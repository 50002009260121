<template>
<main>
    <div class="c-top">
        <ul>
            <li>
                <router-link to="/parcel-suite" class="list">
                    <p>Parcels</p>
                    <div class="c-bullet"></div>
                </router-link>
            </li>

            <li>
                <router-link to="/integrations" class="list">
                    <p>Integrations</p>
                    <div class="c-bullet"></div>
                </router-link>
            </li>
        </ul>
    </div>
    <div class="home">
        <div class="home-wrapper">
            <h1 class="headtitle">Parcel Suite</h1>
            <div class="c-processing-parcel" v-if="orders.length">
                <div class="c-processing-parcel-item">
                    <ul>
                        <li style="margin-left:34px;">Name</li>
                        <li style="margin-left:auto;">Shipping method</li>
                        <li style="margin-left:34px;">Merchant</li>
                        <li style="margin-left:34px;">Date order received</li>
                    </ul>
                </div>
            </div>
            <div class="c-processing-parcel" v-if="orders.length">
                <div v-for="(contact, idx) in orders" :key="idx" class="c-processing-parcel-item">
                    <ul>
                        <li>{{idx + 1}}.</li>
                        <li class="c-order-list">{{contact.CustomerFirstName}} {{contact.CustomerLastName}}
                            <h2 class="c-order">Order</h2>
                            <ul class="c-order-info">
                                <li v-for="(item, idx) in contact.OrderItems" :key="idx" style="padding-bottom:10px;">
                                    <h2>{{item.Quantity}}x {{item.ItemName}}</h2>
                                    <p style="padding:5px 0; font-weight:800;" v-if="item.ItemOptions.length !== 0">Product options: </p>
                                    <p v-for="(options, idx) in item.ItemOptions" :key="idx">- {{options}}</p>
                                </li>
                            </ul>
                        </li>
                        <li class="c-merchant">
                            <img v-if="contact.Merchant === 'Bol.com'" src="../.././assets/bolcom.png" alt="bol.com" class="c-merchant-logo">
                            <img v-if="contact.Merchant === 'Woocommerce'" src="../.././assets/woocommerce-webshop-website-wordpress-logo.png" alt="woocommerce" class="c-merchant-logo">
                        </li>
                        <li class="c-date">
                            {{new Date(contact.DateTimeOrderPlaced).getDate()}}/{{new Date(contact.DateTimeOrderPlaced).getMonth()+1}}/{{new Date(contact.DateTimeOrderPlaced).getFullYear()}}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</main>
</template>

<script>
// @ is an alias to /src
import axios from 'axios';
import * as firebase from 'firebase';
import 'firebase/firestore';
import moment from 'moment'
import {
    mapGetters,
    mapActions
} from "vuex";

import WooCommerceRestApi from "@woocommerce/woocommerce-rest-api";

const WooCommerce = new WooCommerceRestApi({
    url: 'https://mother.life',
    consumerKey: 'ck_d17058bf6863da2dc750fb530ff4ae38757c1528',
    consumerSecret: 'cs_9cdf64d6a5f763ec5b48f569ec80aad598284201',
    wpAPI: true,
    version: 'wc/v3',
    queryStringAuth: true
});

export default {
    name: 'home',
    data() {
        return {
            contacts: [],
            storeOrders: [],
            WoocommerceOpenOrders: [],
            BolComOpenOrders: [],
            orders: []
        }
    },
    props: {
        navigation: {
            type: Object
        }
    },
    components: {

    },
    mounted() {

    },
    created() {

        const getZohoContacts = firebase.functions().httpsCallable('getZohoContacts')
        getZohoContacts().then(result => {
            this.contacts = result.data.access_token.contacts
        })

        WooCommerce.get("orders?status=processing", )
            .then((response) => {
                response.data.forEach((item, i) => {
                    console.log(response.data[i])
                    this.WoocommerceOpenOrders.push({
                        "Merchant": "Woocommerce",
                        "orderId": response.data[i].id,
                        "DateTimeOrderPlaced": response.data[i].date_created,
                        "CustomerFirstName": response.data[i].billing.first_name,
                        "CustomerLastName": response.data[i].billing.last_name,
                        "CustomerEmail": response.data[i].billing.email,
                        "CustomerPhone": response.data[i].billing.phone,
                        "ShippingAddress": {
                            "StreetName": response.data[i].shipping.address_1,
                            "HouseNumber": response.data[i].shipping.address_1,
                            "City": response.data[i].shipping.city,
                            "State": response.data[i].shipping.state,
                            "Postcode": response.data[i].shipping.postcode,
                            "Country": response.data[i].shipping.country,
                        },
                        "BillingAddress": {
                            "StreetName": response.data[i].billing.address_1,
                            "HouseNumber": response.data[i].billing.address_1,
                            "City": response.data[i].billing.city,
                            "State": response.data[i].billing.state,
                            "Postcode": response.data[i].billing.postcode,
                            "Country": response.data[i].billing.country,
                        },
                        "OrderItems": []
                    })
                    response.data[i].line_items.forEach((item, index) => {
                        this.WoocommerceOpenOrders[i].OrderItems.push({
                            "OrderItemId": response.data[i].line_items[index].id,
                            "ItemName": response.data[i].line_items[index].name,
                            "Quantity": response.data[i].line_items[index].quantity,
                            "Price": response.data[i].line_items[index].price,
                            "TransactionFee": "",
                            "ItemOptions": []
                        })
                        response.data[i].line_items[index].meta_data.forEach((item, meta) => {
                            this.WoocommerceOpenOrders[i].OrderItems[index].ItemOptions.push(item.value)
                        })
                    })

                })
            }).then(() => {
                this.storePendingParcels()
                this.getPendingParcels()
            })
            .catch((error) => {
                console.log(error.response.data);
            });

        const getBolOpenOrders = firebase.functions().httpsCallable('getBolOpenOrders')
        getBolOpenOrders().then(result => {
            console.log(result.data.access_token)
            result.data.access_token.forEach((item, i) => {
                this.BolComOpenOrders.push({
                    "Merchant": "Bol.com",
                    "orderId": result.data.access_token[i].orderId,
                    "DateTimeOrderPlaced": result.data.access_token[i].dateTimeOrderPlaced,
                    "CustomerFirstName": result.data.access_token[i].customerDetails.shipmentDetails.firstName,
                    "CustomerLastName": result.data.access_token[i].customerDetails.shipmentDetails.surName,
                    "CustomerEmail": result.data.access_token[i].customerDetails.shipmentDetails.email,
                    "CustomerPhone": result.data.access_token[i].customerDetails.shipmentDetails.surName,
                    "ShippingAddress": {
                        "StreetName": result.data.access_token[i].customerDetails.shipmentDetails.streetName,
                        "HouseNumber": result.data.access_token[i].customerDetails.shipmentDetails.houseNumber,
                        "City": result.data.access_token[i].customerDetails.shipmentDetails.city,
                        "State": result.data.access_token[i].customerDetails.shipmentDetails.state,
                        "Postcode": result.data.access_token[i].customerDetails.shipmentDetails.zipCode,
                        "Country": result.data.access_token[i].customerDetails.shipmentDetails.countryCode
                    },
                    "BillingAddress": {
                        "StreetName": result.data.access_token[i].customerDetails.billingDetails.streetName,
                        "HouseNumber": result.data.access_token[i].customerDetails.billingDetails.houseNumber,
                        "City": result.data.access_token[i].customerDetails.billingDetails.city,
                        "State": result.data.access_token[i].customerDetails.billingDetails.state,
                        "Postcode": result.data.access_token[i].customerDetails.billingDetails.zipCode,
                        "Country": result.data.access_token[i].customerDetails.billingDetails.countryCode
                    },
                    "OrderItems": []
                })
                result.data.access_token[i].orderItems.forEach((item, index) => {
                    this.BolComOpenOrders[i].OrderItems.push({
                        "OrderItemId": result.data.access_token[i].orderItems[index].orderItemId,
                        "ItemName": result.data.access_token[i].orderItems[index].title,
                        "Quantity": result.data.access_token[i].orderItems[index].quantity,
                        "Price": result.data.access_token[i].orderItems[index].offerPrice,
                        "TransactionFee": result.data.access_token[i].orderItems[index].transactionFee,
                        "ItemOptions": []
                    })
                })

            })
        }).then(() => {
            this.storePendingParcels()
            this.getPendingParcels()
        })

    },
    methods: {
        getPendingParcels() {
            firebase.firestore().collection("pending-parcels").get().then((querySnapshot) => {
                this.orders = []
                querySnapshot.forEach((doc) => {
                    console.log(doc.id, " => ", doc.data());
                    this.orders.push(doc.data())
                });
                this.orders.sort(function (a, b) {
                    var da = new Date(a.DateTimeOrderPlaced).getTime();
                    var db = new Date(b.DateTimeOrderPlaced).getTime();

                    return da < db ? -1 : da > db ? 1 : 0
                });
                this.orders.reverse()
                return this.orders
            })
        },
        storePendingParcels() {
            // this.storeOrders.sort(function (a, b) {
            //     var da = new Date(a.DateTimeOrderPlaced).getTime();
            //     var db = new Date(b.DateTimeOrderPlaced).getTime();

            //     return da < db ? -1 : da > db ? 1 : 0
            // });
            // this.storeOrders.reverse()
            this.storeOrders = this.WoocommerceOpenOrders.concat(this.BolComOpenOrders)
            this.storeOrders.forEach((item, index) => {
                for (var key in this.storeOrders[index].ShippingAddress) {
                    if (this.storeOrders[index].ShippingAddress.hasOwnProperty(key)) {
                        //Now, object[key] is the current value
                        if (typeof this.storeOrders[index].ShippingAddress[key] === "undefined")
                            delete this.storeOrders[index].ShippingAddress[key];
                    }
                }
                for (var key in this.storeOrders[index].BillingAddress) {
                    if (this.storeOrders[index].BillingAddress.hasOwnProperty(key)) {
                        //Now, object[key] is the current value
                        if (typeof this.storeOrders[index].BillingAddress[key] === "undefined")
                            delete this.storeOrders[index].BillingAddress[key];
                    }
                }

                firebase.firestore().collection("pending-parcels").doc(item.DateTimeOrderPlaced).set(this.storeOrders[index])
                    .then(() => {
                        console.log("Document successfully written!");
                    })
                    .catch(function (error) {
                        console.error("Error writing document: ", error);
                    });
            })
        }
    },
    computed: {
        ...mapGetters("user", ["authError", "user"]),
        ...mapGetters("shared", ["error"])
    },
}
</script>

<style lang="scss">
@import './src/style/main';

@media (min-width: 71.25em) {
    .home-grid {
        grid-template-columns: 1fr 1fr;
    }
}

@media (min-width: 30em) {
    .home-grid {
        grid-template-columns: 1fr 1fr;
    }
}

#admin-input {
    background: #f6f6fc;
}

.succesText {
    padding-top: 20px;
    font-size: 18px;
}

.headtitle {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 60px;
}

.er {
    color: #ED5E68;
}

.home-grid {
    margin-bottom: 0;

    .addItem {
        margin-bottom: 30px;
        height: 240px;

        p,
        .link-users {
            text-align: center;
        }

        .title {
            text-align: center;
            margin-bottom: 0;
        }

        a {
            margin-top: 12px !important;
            color: #037362;
            width: 100%;
            position: absolute;
            left: 50%;
            transform: translateX(-50%)
        }

    }
}

.counter {
    font-size: 120px;
    text-align: center;
}

.addItem {
    background: #ffffff;
    padding: 35px;
    min-height: 175px;
    border-radius: 10px;
    margin-bottom: 100px;
    position: relative;
}
</style>
