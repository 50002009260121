<template>
<div class="home">
    <h1 class="title">{{this.$route.params.recipeId}}</h1>

    <div class="whole-add-item">
        <div class="addItem">
            <div v-if="!uploadEnd" class="addItem item-video">
                <h1 class="title title-upload">Upload recipe image</h1>

                <input id="files" type="file" name="file" ref="uploadInput" accept="image/*" :multiple="false" @change="detectFiles($event)" />
                <button @click="selectFile" v-if="!uploading" class="btn-upload">
                    Choose Image
                </button>

                <div v-if="uploading" style="text-align:center; font-size:20px">
                    {{progressUpload}}%
                </div>
            </div>
            <img v-if="uploadEnd" :src="recipes.downloadURL" width="100%" class="upload-image" />
            <div v-if="uploadEnd">
                <button class="ma-0" dark small color="error" @click="deleteImage()" style="cursor:pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" viewBox="0 0 191.414 191.414" enable-background="new 0 0 191.414 191.414" width="512px" height="512px" class="svg-delete">
                        <g>
                            <g>
                                <path d="M107.888,96.142l80.916-80.916c3.48-3.48,3.48-8.701,0-12.181s-8.701-3.48-12.181,0L95.707,83.961L14.791,3.045   c-3.48-3.48-8.701-3.48-12.181,0s-3.48,8.701,0,12.181l80.915,80.916L2.61,177.057c-3.48,3.48-3.48,8.701,0,12.181   c1.74,1.74,5.22,1.74,6.96,1.74s5.22,0,5.22-1.74l80.916-80.916l80.916,80.916c1.74,1.74,5.22,1.74,6.96,1.74   c1.74,0,5.22,0,5.22-1.74c3.48-3.48,3.48-8.701,0-12.181L107.888,96.142z" data-original="#000000" class="active-path" data-old_color="#000000" fill="#FFFFFF" />
                            </g>
                        </g>
                    </svg>
                </button>
            </div>

            <form pre>
                <div class="input-field">
                    <label class="label-text">Recipe name</label>
                    <input class="input-auth inputApp" v-model:value="recipes.recipeName" type="text">
                </div>
                <div class="input-field">
                    <label class="label-text">Introducing recipe</label>
                    <textarea class="input-auth inputApp" style="padding:15px;" placeholder="Introduction" type="text" rows="5" v-model:value="recipes.introduction"></textarea>
                </div>
                                <div class="input-field">
                    <label class="label-text">Recipe type</label>
                    <select v-model:value="recipes.recipeType" class="input-auth inputApp ingredient">
                        <option value="nothing" selected="selected">Nothing</option>
                        <option value="Drink">Drink</option>
                        <option value="Dip">Dip</option>
                        <option value="Main course">Main course</option>
                        <option value="Starter">Starter</option>
                        <option value="Side">Side</option>
                    </select>
                </div>
                <div class="input-field">
                    <label class="label-text">How much time does this recipe take?</label>
                    <input class="input-auth inputApp" placeholder="250" type="number" style="width:53px; margin-right:10px;" v-model:value="recipes.timeMakingIt">minutes
                </div>
                <div class="input-field">
                    <label class="label-text">How many servings are in your recipe?</label>
                    <input class="input-auth inputApp" placeholder="2" type="number" style="width:53px; margin-right:10px;" v-model:value="recipes.persons">person(s)
                </div>
                <table class="input-field ingredient-input rec">
                    <thead>
                        <tr>
                            <label class="label-text">Ingredients</label>
                        </tr>
                    </thead>
                    <tbody class="table rec">
                        <tr v-for="(row, index) in recipes.ingredients" :key="index">
                            <td width="10%"><input type="number" step="0.5" v-model:value="row.ingredientQuantity" class="input-auth inputApp ingredient" placeholder="0" style="margin-right:5px;"></td>
                            <td width="15%">
                                <select v-model:value="row.ingredientEntity" class="input-auth inputApp ingredient">
                                    <option value="nothing" selected="selected">Nothing</option>
                                    <option value="amount">Amount</option>
                                    <option value="g">g</option>
                                    <option value="ml">ml</option>
                                </select>
                            </td>
                            <td width="35%"><input type="text" class="input-auth inputApp ingredient" v-model:value="row.ingredientName" placeholder="Ingredient Name"></td>
                            <td width="10%"><input type="number" step="0.5" v-model:value="row.cupQuantity" class="input-auth inputApp ingredient" placeholder="0" style="margin-right:5px;"></td>
                            <td width="15%">
                                <select v-model:value="row.cupEntity" class="input-auth inputApp ingredient">
                                    <option value="nothing" selected="selected">Nothing</option>
                                    <option value="cup">cup</option>
                                    <option value="cups">cups</option>
                                    <option value="tablespoon">tablespoon</option>
                                    <option value="tablespoons">tablespoons</option>
                                    <option value="teaspoon">teaspoon</option>
                                    <option value="teaspoons">teaspoons</option>
                                </select>
                            </td>
                            <td width="5%">
                                <a v-on:click="removeElement(index);" style="cursor: pointer;font-size:10px;" class="btn-remove">

                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" viewBox="0 0 191.414 191.414" enable-background="new 0 0 191.414 191.414" width="512px" height="512px" class="svg-delete">
                                        <g>
                                            <g>
                                                <path d="M107.888,96.142l80.916-80.916c3.48-3.48,3.48-8.701,0-12.181s-8.701-3.48-12.181,0L95.707,83.961L14.791,3.045   c-3.48-3.48-8.701-3.48-12.181,0s-3.48,8.701,0,12.181l80.915,80.916L2.61,177.057c-3.48,3.48-3.48,8.701,0,12.181   c1.74,1.74,5.22,1.74,6.96,1.74s5.22,0,5.22-1.74l80.916-80.916l80.916,80.916c1.74,1.74,5.22,1.74,6.96,1.74   c1.74,0,5.22,0,5.22-1.74c3.48-3.48,3.48-8.701,0-12.181L107.888,96.142z" data-original="#000000" class="active-path" data-old_color="#000000" fill="#FFFFFF" />
                                            </g>
                                        </g>
                                    </svg></a>
                            </td>

                        </tr>
                    </tbody>
                </table>
                <div>
                    <button class="btn-add" v-on:click="addRow">Add ingredient</button>
                </div>

                <table class="input-field dynamic-input rec">
                    <thead>
                        <tr>
                            <label class="label-text">Instructions</label>
                        </tr>
                    </thead>
                    <tbody class="table rec">
                        <tr v-for="(row, index) in recipes.instruction" :key="index" style="margin-bottom:20px">
                            <td width="5%" style="vertical-align:middle;">
                                <div class="step">
                                    <p>{{ index + 1 }}</p>
                                </div>
                            </td>
                            <td><textarea width="90%" style="padding:15px;" class="input-auth inputApp" type="text" rows="5" v-model:value="row.instructionStep"></textarea></td>
                            <td width="5%" style="vertical-align:middle;">
                                <a v-on:click="removeInstruction(index);" style="cursor: pointer;font-size:10px;" class="btn-remove">

                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" viewBox="0 0 191.414 191.414" enable-background="new 0 0 191.414 191.414" width="512px" height="512px" class="svg-delete">
                                        <g>
                                            <g>
                                                <path d="M107.888,96.142l80.916-80.916c3.48-3.48,3.48-8.701,0-12.181s-8.701-3.48-12.181,0L95.707,83.961L14.791,3.045   c-3.48-3.48-8.701-3.48-12.181,0s-3.48,8.701,0,12.181l80.915,80.916L2.61,177.057c-3.48,3.48-3.48,8.701,0,12.181   c1.74,1.74,5.22,1.74,6.96,1.74s5.22,0,5.22-1.74l80.916-80.916l80.916,80.916c1.74,1.74,5.22,1.74,6.96,1.74   c1.74,0,5.22,0,5.22-1.74c3.48-3.48,3.48-8.701,0-12.181L107.888,96.142z" data-original="#000000" class="active-path" data-old_color="#000000" fill="#FFFFFF" />
                                            </g>
                                        </g>
                                    </svg>
                                </a>
                            </td>

                        </tr>
                    </tbody>
                </table>
                <div>
                    <button class="btn-add" v-on:click="addInstruction">Add instruction</button>
                </div>

                <button v-on:click="submitRecipe" style="margin-top:50px" class="btn-auth">Update recipe</button>
            </form>
        </div>
        <section v-if="validateRecipe === true" v-on:click="cancelRecipe" class="back-prompt"></section>
        <div class="prompt" v-if="validateRecipe === true">
            <div class="header-validate">
                <h1 class="title title-validate">Everything ready to go live?</h1>
            </div>
            <p class="text-validate">If you validate {{ recipes.recipeName }}, the recipe will be put live on the Android and iOS application. This will add it to the category 'New recipes'.</p>
            <button v-on:click="cancelRecipe" class="btn-cancel">Cancel</button>
            <button v-on:click="PushToFirestore" class="btn-validate">Validate</button>
        </div>
    </div>
</div>
</template>

<script>
import Sortable from 'sortablejs';
import axios from 'axios';
// @ is an alias to /src
import Item from '.././components/item'
import AddItem from '.././components/addItem'
import * as firebase from "firebase";
import {
    mapGetters,
    mapActions
} from "vuex";
require('firebase/firestore');

export default {
    data: function () {
        return {
            validateRecipe: false,
            progressUpload: 0,
            uploadTask: '',
            uploading: false,
            selectForDelete: '',
            deleteRecipe: false,
            uploadEnd: true,
            recipes: {
                downloadURL: '',
                imageFileName: '',
                recipeName: '',
                ingredients: [],
                instruction: [],
                timeMakingIt: '',
                persons: '',
                introduction: '',
                dateAdded: Date()
            },
            RecipeName: this.$route.params.recipeId
        };
    },
    name: 'recipes',
    components: {
        Item,
        AddItem
    },
    created() {
        firebase.firestore().doc(`recipes/${this.RecipeName}`)
            .get()
            .then(doc => {
                console.log(doc.data())
                this.recipes = doc.data()
                return this.recipes
            })
    },
    methods: {
        submitRecipe(e) {
            e.preventDefault()
            this.validateRecipe = true
        },

        cancelRecipe() {
            this.validateRecipe = false
        },

        addInstruction(e) {
            e.preventDefault()
            this.recipes.instruction.push({
                instructionStep: "",
            });
        },
        removeInstruction: function (index) {
            this.recipes.instruction.splice(index, 1);
        },

        addRow(e) {
            e.preventDefault()
            this.recipes.ingredients.push({
                ingredientQuantity: "",
                ingredientEntity: "nothing",
                ingredientName: "",
                cupQuantity: "",
                cupEntity: "nothing",

            });
        },
        removeElement: function (index) {
            this.recipes.ingredients.splice(index, 1);
        },
        PushToFirestore(e) {
            e.preventDefault()
            const routeName = this.$route.params.recipeId
            const recipeName = this.recipes.recipeName
            const recipes = this.recipes
            let collection = firebase.firestore().collection('recipes').doc(routeName).get().then(function (doc) {
                if (routeName !== recipeName) {
                    firebase.firestore().collection("recipes").doc(recipeName).set(recipes).then(
                        firebase.firestore().collection("recipes").doc(routeName).delete()
                    );
                } else {
                    firebase.firestore().collection("recipes").doc(recipeName).set(recipes)
                }
            });

            this.$router.push({
                name: 'recipes'
            });
        },
        selectFile() {
            this.$refs.uploadInput.click()
        },
        detectFiles(e) {
            console.log("test")
            let fileList = e.target.files || e.dataTransfer.files
            Array.from(Array(fileList.length).keys()).map(x => {
                this.upload(fileList[x])
            })
        },
        upload(file) {

            console.log('????? data:', localStorage.getItem('uid'));
            this.recipes.imageFileName = file.name
            this.uploading = true
            this.uploadTask = firebase.storage().ref('images/' + file.name).put(file)
        },
        deleteImage() {
            firebase
                .storage()
                .ref('images/' + this.recipes.imageFileName)
                .delete()
                .then(() => {
                    this.uploading = false
                    this.uploadEnd = false
                    this.recipes.downloadURL = ''
                })
                .catch((error) => {
                    console.error(`file delete error occured: ${error}`)
                })
        }
    },
    watch: {
        uploadTask: function () {
            this.uploadTask.on('state_changed', sp => {
                    this.progressUpload = Math.floor(sp.bytesTransferred / sp.totalBytes * 100)
                },
                null,
                () => {
                    this.uploadTask.snapshot.ref.getDownloadURL().then(url => {
                        this.uploadEnd = true
                        this.recipes.downloadURL = url
                        this.$emit(this.recipes.downloadURL, url)
                    })
                })
        }
    }
}
</script>

<style lang="scss">
@import './src/style/main';

.item-video {
    background: #f6f6fc;
    margin-bottom: 8px;
    min-height: 175px;
}

.delete-button {
    position: relative;
    float: right;
    z-index: 20;
    bottom: 230px;
    border: none;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 100%;
    height: 37px;
    color: #ffffff;
    background: #ED5E68;
    font-size: 15px;
    cursor: pointer;
}

.edit-button {
    float: right;
    position: relative;
    cursor: pointer;
    background-color: #000000;
    color: white;
    text-decoration: none;
    height: 40px;
    width: 120px;
    border: none;
    border-radius: 10px;
    font-weight: bold;
    -webkit-box-shadow: 0px 0px 31px -10px #000000;
    box-shadow: 0px 0px 31px -10px #000000;
    -webkit-transition-duration: 600ms;
    transition-duration: 600ms;
    bottom: 68px;
    font-size: 14px;
    font-weight: 400;
}

#Capa_1 {
    width: 100px;
    height: 100px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%);
}

.Add-info {
    width: 100%;
    margin: 0;
    padding: 0;
    position: absolute;
    bottom: -10px;

    .Add-fullname {
        left: 50%;
        transform: translateX(-50%);
        position: absolute;
        bottom: -51px;
    }
}

.add-stock {
    margin: unset !important;
}

.add-graph {
    box-shadow: unset !important;
    transition-duration: 600ms;
}

.prompt {
    z-index: 1000;
    transition-duration: 600ms;
    width: 97%;
    max-width: 500px;
    height: 250px;
    background: #ffffff;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.back-prompt {
    z-index: 999;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, .32);
}

.btn-delete {
    background: #ED5E68;
    width: 109px;
    position: absolute;
    right: 17px;
    bottom: 29px;
    font-size: 14px;
}

.title-delete {
    position: absolute;
    top: 26px;
    left: 17px;
    color: #ffffff;
}

.btn-cancel {
    background: #ffffff;
    width: 91px;
    position: absolute;
    right: 129px;
    bottom: 29px;
    font-size: 14px;
    color: rgba(0, 0, 0, .54);
    -webkit-box-shadow: unset;
    box-shadow: unset;
}

.btn-cancel:hover {
    box-shadow: unset;
}

.text-delete {
    position: absolute;
    left: 17px;
    top: 100px;
    line-height: 21px;
}

.header-delete {
    width: 100%;
    background: #ED5E68;
    height: 78px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.progress-bar {
    margin: 10px 0;
}

input[type="file"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
}

.whole-add-item {
    margin-left: 250px;
    max-width: 1000px;
    width: 100%;
    margin: auto
}

.addItem {
    background: #ffffff;
    padding: 35px;
    min-height: 175px;
    border-radius: 10px;
    margin-bottom: 100px;
    position: relative;
}

.upload-image {
    border-radius: 10px;
}

.ma-0 {
    position: absolute;
    top: 47px;
    width: 32px;
    padding-bottom: 4px;
    height: 32px;
    border-radius: 33px;
    right: 47px;
    border: 0;
    color: #ffffff;
    background: #ED5E68;
}

.title {
    font-size: 32px;
    margin-bottom: 20px;
    margin-bottom: 33px;
}

.btn-upload {
    border: 0;
    background: #000000;
    height: 49px;
    width: 174px;
    border-radius: 10px;
    position: absolute;
    left: 50%;
    bottom: 35px;
    transform: translateX(-50%);
    color: #fff;
    font-size: 14px;
    cursor: pointer;
}

.upload-an-image {
    position: absolute;
    margin-left: 124px;
    max-width: 415px;
    width: 90%;
    height: 174px;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.title-upload {
    text-align: center;
}

button:focus {
    outline: 0;
}

.dynamic-input {
    width: 100%
}

.ingredient-input {
    width: 100% !important;
    min-width: 400px;

    tbody {
        tr {
            td {
                border-right: 5px solid #fff;
            }
        }
    }
}

.step {
    background: #000000;
    color: #ffffff;
    width: 25px;
    height: 25px;
    padding: 0;
    margin: 0;
    border-radius: 25px;

}

.step p {
    margin: auto;
    text-align: center;
    padding-top: 5px;
    font-size: 14px;
}

textarea {
    height: unset !important
}

tbody {
    margin-top: 20px;
}

.btn-remove {
    background: #ED5E68;
    color: #ffffff;

    padding: 12px 9px 9px 9px;
    margin-left: 13px;
    border-radius: 25px;
}

.svg-delete {
    width: 15px;
    padding-top: 4px;
    height: 16px;
}

.btn-add {
    border-style: none;
    color: #000000;
    font-size: 15px;
    padding: 0;
    margin-top: 20px;
    text-decoration: underline;
    cursor: pointer;
}

.ingredient {
    width: 90%;
    padding-right: 10px;
}

.table tr>td {
    padding-bottom: 5px;
}

.prompt {
    transition-duration: 600ms;
    width: 97%;
    max-width: 500px;
    height: 250px;
    background: #ffffff;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.back-prompt {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, .32);
}

.btn-validate {
    height: 40px;
    border-radius: 10px;
    border: none;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    margin-top: 20px;
    -webkit-transition-duration: 600ms;
    transition-duration: 600ms;
    background: #000000;
    width: 109px;
    position: absolute;
    right: 17px;
    bottom: 29px;
    font-size: 14px;
}

.title-validate {
    position: absolute;
    top: 26px;
    left: 17px;
    color: #ffffff;
}

.btn-cancel {
    background: #ffffff;
    width: 91px;
    position: absolute;
    right: 129px;
    bottom: 29px;
    font-size: 14px;
    color: rgba(0, 0, 0, .54);
    -webkit-box-shadow: unset;
    box-shadow: unset;
}

.btn-cancel:hover {
    box-shadow: unset;
}

.text-validate {
    position: absolute;
    left: 17px;
    top: 100px;
    line-height: 21px;
}

.header-validate {
    width: 100%;
    background: #000000;
    height: 78px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.rec tr {
    border-bottom: 0 !important;
}
</style>
