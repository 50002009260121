<template>
<div id="app">

    <div class="center">
        <div class="left">

            <img src="./assets/logo.png" alt="Company Logo" class="logo-desktop">

            <div class="navigation">

                <router-link to="/" class="list">
                    <p>Dashboard</p>
                </router-link>
            </div>
            <button v-on:click="logout" class="c-button-primary btn">Logout</button>
        </div>
        <div class="right">
            <img src="./assets/logo-black.png" alt="Company Logo" class="logo-mobile">
            <router-view />
            <p class="version">Version: 2.0</p>
        </div>
    </div>
</div>
</template>

<script>
// @ is an alias to /src 
import firebase from 'firebase';

export default {
    name: 'App',

    methods: {
        logout: function () {
            firebase.auth().signOut().then(() => {
                this.$router.replace('login')
            })
        }
    }
}
</script>

<style lang="scss">
@import './src/style/main';
@import './src/style/components.footer';

.left .navigation .router-link-exact-active {
    color: #000000;
}

.btn {
    position: absolute !important;
    bottom: 20px !important;
}

.version {
    position: fixed;
    right: 14px;
    bottom: 14px;
    color: lightgray;
}
</style>
