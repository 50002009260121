import Vue from 'vue'
import VueResource from 'vue-resource'
import VeeValidate from 'vee-validate'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import firebase from 'firebase';
import 'firebase/firestore';
import firebaseConfig from './firebaseConfig';
const firebaseApp = firebase.initializeApp(firebaseConfig);
import Sortable from 'sortablejs';
import Vuex from 'vuex'
import { firestorePlugin } from 'vuefire'
import VuePlyr from "vue-plyr";
import "vue-plyr/dist/vue-plyr.css";
Vue.use(VuePlyr);
Vue.use(firestorePlugin)

Vue.use(Vuex)

Vue.config.productionTip = false;

Vue.use(require('vue-moment'));
Vue.use(VueResource);
Vue.use(VeeValidate);
// Vue.use(VueFire);

// this allows us access to the vuex-store in all of the components
Vue.prototype.$store = store;

export const db = firebaseApp.firestore();

Vue.directive('sortable', {
    params: ['sorting'],
    update(options = {}) {
      const sorting = this.params.sorting;
      if (sorting) {
        options.onUpdate = function sortableUpdate(e) {
          const deleted = sorting.splice(e.oldIndex, 1);
          sorting.splice(e.newIndex, 0, deleted[0]);
        };
      }
  
      Sortable.create(this.el, options);
    },
  });

let app;
firebase.auth().onAuthStateChanged(function(user) {
    if (!app) {
        /* eslint-disable no-new */
        app = new Vue({

            router,
            store,
            render: h => h(App)
        }).$mount('#app');
    }
});


