import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import Microgreens from './views/Microgreens.vue'
import MicrogreensDetail from './views/MicrogreensDetail.vue'
import Recipes from './views/Recipes.vue'
import RecipesDetail from './views/RecipesDetail.vue'
import Login from './views/Login';
import firebase from 'firebase';
import AddMicrogreen from './views/AddMicrogreen.vue'
import AddRecipe from './views/AddRecipe.vue'
import Videos from './views/Videos.vue'
import AddVideos from './views/AddVideos.vue'
import VideosDetail from './views/VideosDetail.vue'
import AllUsers from './views/AllUsers.vue'
import AdminUsers from './views/AdminUsers.vue'
import ParcelSuite from './views/ParcelSuite/ParcelSuite.vue'
import Integrations from './views/ParcelSuite/Integrations.vue'

Vue.use(Router);

let router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/microgreens',
        name: 'microgreens',
        component: Microgreens,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/all-users',
        name: 'all-users',
        component: AllUsers,
        meta: {
            requiresAuth: true
        }
    },    
    {
        path: '/admin-users',
        name: 'admin-users',
        component: AdminUsers,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/add-microgreen',
        name: 'add-microgreen',
        component: AddMicrogreen,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/add-recipe',
        name: 'add-recipe',
        component: AddRecipe,
        meta: {
            requiresAuth: true
        }
    },
      {
          path: '/recipes',
          name: 'recipes',
          component: Recipes,
          meta: {
              requiresAuth: true
          }
      },
      {
        path: '/add-tutorial',
        name: 'add-tutorial',
        component: AddVideos,
        meta: {
            requiresAuth: true
        }
    },
      {
          path: '/tutorial',
          name: 'tutorial',
          component: Videos,
          meta: {
              requiresAuth: true
          }
      },
     
      {
          path: '/login',
          name: 'login',
          component: Login,
          meta: {
              requiresGuest: true
          }
      },
      {
        path: '/recipes/:recipeId',
        name: 'RecipesDetail',
        component: RecipesDetail,
        props: true,
        meta: {
            requiresAuth: true
        }
      },
      {
        path: '/tutorial/:tutorialId',
        name: 'VideosDetail',
        component: VideosDetail,
        props: true,
        meta: {
            requiresAuth: true
        }
      },
      {
        path: '/microgreens/:microgreenId',
        name: 'MicrogreensDetail',
        component: MicrogreensDetail,
        props: true,
        meta: {
            requiresAuth: true
        }
      },
      {
        path: '/parcel-suite',
        name: 'ParcelSuite',
        component: ParcelSuite,
        props: true,
        meta: {
            requiresAuth: true
        }
      },
      {
        path: '/integrations',
        name: 'Integrations',
        component: Integrations,
        props: true,
        meta: {
            requiresAuth: true
        }
      },
      { path: "*", redirect: "/" },
  ],
  linkActiveClass: "active"
});

router.beforeEach((to, from, next) => {
    // Check for requiresAuth guard
    if (to.matched.some(record => record.meta.requiresAuth)) {
        // Check if NO logged user
        if (!firebase.auth().currentUser) {
            // Go to login
            next({
                path: '/login',
                query: {
                    redirect: to.fullPath
                }
            });
        } else {
            // Proceed to route
            next();
        }
    } else if (to.matched.some(record => record.meta.requiresGuest)) {
        // Check if NO logged user
        if (firebase.auth().currentUser) {
            // Go to login
            next({
                path: '/',
                query: {
                    redirect: to.fullPath
                }
            });
        } else {
            // Proceed to route
            next();
        }
    } else {
        // Proceed to route
        next();
    }
});


export default router;
