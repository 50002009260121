<template>
<main>
      <div class="c-top">
        <ul>
            <li>
                <router-link to="/" class="list">
                    <p>Overview</p>
                    <div class="c-bullet"></div>
                </router-link>
            </li>

            <li>
                <router-link to="/microgreens" class="list">
                    <p>Microgreens</p>
                    <div class="c-bullet"></div>
                </router-link>
            </li>
            <li>
                <router-link to="/recipes" class="list">
                    <p>Recipes</p>
                    <div class="c-bullet"></div>
                </router-link>
            </li>
            <li>
                <router-link to="/tutorial" class="list">
                    <p>Tutorials</p>
                    <div class="c-bullet"></div>
                </router-link>
            </li>
        </ul>
    </div>
  <div class="home">
    <div class="home-wrapper">
    <div class="title">Microgreens</div>
    <div class="description">Here you can add, update or delete microgreens stored in the Mother application</div>
    <button @click="editMicrogreen()" class="edit-button col-sm-4">{{ edit }}</button>
    <div class="container">
      <div class="o-grid">
        
        <router-link to="/add-microgreen" class="graph col-sm-4 add-graph">
          <div class="stock add-stock">
              <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 42 42" style="enable-background:new 0 0 42 42;" xml:space="preserve" color="#808080" data-old_color="#808080" >
              <polygon points="42,19 23,19 23,0 19,0 19,19 0,19 0,23 19,23 19,42 23,42 23,23 42,23 " style="fill:#E0E0E0"/>
              </svg>
            <div class="stock-info Add-info">
              <div class="stock-fullname Add-fullname">Add a microgreen</div>
            </div>
          </div>
        </router-link>

        <ul v-for="(microgreen, idx) in microgreens" :key="idx" v-sortable :sorting="microgreens" :class="jingle">
            <li  >
              <Item
              v-bind:img="microgreen.downloadURL1"
              v-bind:name="microgreen.microgreenName"
              v-bind:link="{ name: 'MicrogreensDetail', params: { microgreenId: microgreen.microgreenName }}"
              >
            
              </Item>
              <button v-if="edit == 'Done'" v-on:click="deleteWarning(microgreen.microgreenName, microgreen.imageFileName)" class="delete-button col-sm-4">
               <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" viewBox="0 0 191.414 191.414" enable-background="new 0 0 191.414 191.414" width="512px" height="512px" class="svg-delete"><g><g><path d="M107.888,96.142l80.916-80.916c3.48-3.48,3.48-8.701,0-12.181s-8.701-3.48-12.181,0L95.707,83.961L14.791,3.045   c-3.48-3.48-8.701-3.48-12.181,0s-3.48,8.701,0,12.181l80.915,80.916L2.61,177.057c-3.48,3.48-3.48,8.701,0,12.181   c1.74,1.74,5.22,1.74,6.96,1.74s5.22,0,5.22-1.74l80.916-80.916l80.916,80.916c1.74,1.74,5.22,1.74,6.96,1.74   c1.74,0,5.22,0,5.22-1.74c3.48-3.48,3.48-8.701,0-12.181L107.888,96.142z" data-original="#000000" class="active-path" data-old_color="#000000" fill="#FFFFFF"/></g></g> </svg>
              </button> 
              <button v-else class="delete-button col-sm-4" style="opacity:0"/>
            </li>
        </ul>
        <section v-if="deleteMicrogreen === true" v-on:click="cancelRecipe" class="back-prompt"></section>
        <div class="prompt" v-if="deleteMicrogreen === true">
            <div class="header-delete">
              <h1 class="title title-delete">Do you want to delete this microgreen?</h1>
            </div>
            <p class="text-delete">If you delete microgreen, the microgreen will be removed directly from the Android and iOS application. There's no way to get it back.</p>
            <button v-on:click="cancelRecipe" class="btn-cancel">Cancel</button>
            <button v-on:click="deleteMicrogreenById()" class="btn-delete">Delete</button>
        </div>

      </div>
    </div>
    </div>
  </div>
</main>
</template>

<script>
import Sortable from 'sortablejs';
  import axios from 'axios';
// @ is an alias to /src
import Item from '.././components/item'
import AddItem from '.././components/addItem'
import * as firebase from "firebase";
import { mapGetters, mapActions } from "vuex";
require('firebase/firestore');

export default {
 props:[ 'cardName'],
  data: function() {
    return {
      selectForDelete:'',
      deleteMicrogreen:false,
      microgreens: [],
      edit: 'Edit',
      jingle: ''
    };
  },
  name: 'microgreens',
  components: {
      Item,
      AddItem
  },
  created() {
    firebase.firestore().collection('microgreens').get()
        .then(querySnapshot => {
            querySnapshot.docs.forEach(doc => {
                this.microgreens.push(doc.data());
            });
        });
    console.log(this.microgreens);
    return this.microgreens;
  },
  methods: {
    deleteWarning(microgreen, fileName) {  
      localStorage.setItem('microgreen-id', microgreen);  
      localStorage.setItem('img-file-name', fileName);
      this.deleteMicrogreen = true
    },
    cancelRecipe(){     
      this.deleteMicrogreen = false
    },
    // ...omitted
    deleteMicrogreenById() {
      let getId = localStorage.getItem('microgreen-id');
      let getFileName = localStorage.getItem('img-file-name');
      firebase.firestore().collection('microgreens').doc(getId).delete()
      this.microgreens = []
      firebase.storage().ref('images/' + getFileName).delete()
      this.deleteMicrogreen = false
      localStorage.removeItem('microgreen-id');
      localStorage.removeItem('img-file-name');
      firebase.firestore().collection('microgreens').get()
        .then(querySnapshot => {
            querySnapshot.docs.forEach(doc => {
                this.microgreens.push(doc.data());
            });
        });
      console.log(this.microgreens);
      return this.microgreens;
    },
    editMicrogreen () {
      if(this.edit === 'Edit')
      {
        this.edit = 'Done'
        this.jingle = 'vibrate-1'
      }else{
        this.edit = 'Edit'
        this.jingle = ''
      }
    }
  }
}
</script>

<style lang="scss">
  @import './src/style/main';
  .btn-delete{
  height: 40px;
  border-radius: 10px;
    border: none;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    margin-top: 20px;
    transition-duration: 600ms;
    background: #ED5E68;
    width: 109px;
    position: absolute;
    right: 17px;
    bottom: 29px;
    font-size: 14px;
}


.btn-cancel{
    height: 40px;
  border-radius: 10px;
    border: none;
    font-size: 16px;
    cursor: pointer;
    margin-top: 20px;
    transition-duration: 600ms;
    background: #ffffff;
    width: 91px;
    position: absolute;
    right: 129px;
    bottom: 29px;
    font-size: 14px;
    color: rgba(0,0,0,.54);
    -webkit-box-shadow: unset;
    box-shadow: unset;
}
.delete-button{
    position: relative;
    float: right;
    z-index: 20;
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;
    bottom: 220px;
    width: 32px;
    padding-bottom: 4px;
    height: 32px;
    border-radius: 33px;
    right: 11px;
    border: 0;
    color: #ffffff;
    background: #ED5E68;
}
.edit-button{
  float: right;
    position: relative;
    cursor: pointer;
    background-color: #000000;
    color: white;
    text-decoration: none;
    height: 40px;
    width: 120px;
    border: none;
    border-radius: 10px;
    font-weight: bold;
    -webkit-box-shadow: 0px 0px 31px -10px #000000;
    box-shadow: 0px 0px 31px -10px #000000;
    -webkit-transition-duration: 600ms;
    transition-duration: 600ms;
    bottom: 68px;
    font-size: 14px;
    font-weight: 400;
}
#Capa_1{
    width: 100px;
    height: 100px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%);
}
.Add-info{
  width: 100%;
  margin: 0;
  padding: 0;
  position: absolute;
  bottom: -10px;
  .Add-fullname{
    left: 50%;
    transform:translateX(-50%);
    position: absolute;
    bottom: -51px;
  }
}
.add-stock{
  margin: unset!important;
}

.add-graph{
  box-shadow: unset!important;
  transition-duration: 600ms;
}
.add-graph:hover{
  box-shadow: 0px 0px 22px -8px #808080!important;
}
</style>

