<template>
  <div class="home">
    <div class="title">All users</div>
    <div class="addItem">
      <button v-on:click="listAllUsers" class="reload">
          <img src=".././assets/update-arrow.png" alt="reload" >
      </button>
      
        <table>
          <tr>
            <th>Email</th>
            <th>Email verified</th>
            <th>Gemaakt</th>
            <th>Ingelogd</th>
            <th>UID</th>
          </tr>
            <tr v-for="(user, idx) in allUsers" :key="idx">
             <td>{{ user.email }}</td>
             <td v-if="user.emailVerified === true">Yes</td>
             <td v-else>No</td>
             <td>{{ new Date(user.metadata.creationTime).getDate()}}/{{ new Date(user.metadata.creationTime).getMonth()}}/{{ new Date(user.metadata.creationTime).getFullYear() }}</td>
             <td>{{ new Date(user.metadata.lastSignInTime).getDate()}}/{{ new Date(user.metadata.lastSignInTime).getMonth()}}/{{ new Date(user.metadata.lastSignInTime).getFullYear() }}</td>
             <td>{{ user.uid }}</td>
            </tr>
        </table>
    </div>
  </div>
</template>

<script>
import Sortable from 'sortablejs';
import axios from 'axios';
import Item from '.././components/item'
import AddItem from '.././components/addItem'
import * as firebase from "firebase";
import { mapGetters, mapActions } from "vuex";
require('firebase/firestore');

export default {
  data: function() {
    return {
      allUsers:[]
    };
  },
  name: 'recipes',
  components: {
      Item,
      AddItem,
  },
  computed: {
  },
  created() {
    this.listAllUsers()
  },
  methods: {
      listAllUsers: function(e){
      const listAllUsers = firebase.functions().httpsCallable('listAllUsers')
      listAllUsers().then(result => {
          this.allUsers = result.data.message
          console.log(this.allUsers)
          
      }).catch(err => {
          
      })
      e.preventDefault()
    }
  }
}
</script>

<style lang="scss">
  @import './src/style/main';
  .reload{
        float: right;
        border:none;
        cursor: pointer;
            position: relative;
    top: 21px;
    img{
      width: 19px;
    }
    &:active{
	-webkit-animation: rotate-center 0.6s ease-in-out both;
	        animation: rotate-center 0.6s ease-in-out both;
    }
  }
.list{
  display: inline;
}
table{
      width: 100%;
      th{
        text-align: left;
        font-weight: bold
      }
      tr{
        height: 54px;
        border-bottom: 1px solid lightgray;
      }
      tr:first-child{
        height: 25px;
      }
      td{
        padding-top: 17px;
      }
}
@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
</style>

