<template>
<main>
        <div class="c-top">
        <ul>
            <li>
                <router-link to="/" class="list">
                    <p>Overview</p>
                    <div class="c-bullet"></div>
                </router-link>
            </li>

            <li>
                <router-link to="/microgreens" class="list">
                    <p>Microgreens</p>
                    <div class="c-bullet"></div>
                </router-link>
            </li>
            <li>
                <router-link to="/recipes" class="list">
                    <p>Recipes</p>
                    <div class="c-bullet"></div>
                </router-link>
            </li>
            <li>
                <router-link to="/tutorial" class="list">
                    <p>Tutorials</p>
                    <div class="c-bullet"></div>
                </router-link>
            </li>
        </ul>
    </div>
  <div class="home">
    <div class="home-wrapper">
    <div class="title">tutorials</div>
    <div class="description">Here you can add, update or delete tutorials stored in the Mother application.</div>
    <button @click="edittutorials()" class="edit-button col-sm-4">{{ edit }}</button>
    <div class="container">
      <div class="o-grid">
        
        <router-link to="/add-tutorial" class="graph col-sm-4 add-graph">
          <div class="stock add-stock">
              <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 42 42" style="enable-background:new 0 0 42 42;" xml:space="preserve" color="#808080" data-old_color="#808080" >
              <polygon points="42,19 23,19 23,0 19,0 19,19 0,19 0,23 19,23 19,42 23,42 23,23 42,23 " style="fill:#E0E0E0"/>
              </svg>
            <div class="stock-info Add-info">
              <div class="stock-fullname Add-fullname">Add a tutorial</div>
            </div>
          </div>
        </router-link>

        <ul v-for="(tutorial, idx) in tutorials" :key="idx" v-sortable :sorting="tutorials" :class="jingle">
            <li >
              <Item
              v-bind:img="tutorial.imageURL"
              v-bind:name="tutorial.tutorialName"
              v-bind:link="{ name: 'VideosDetail', params: { tutorialId: tutorial.tutorialName }}"
              >
              </Item>
              <button v-if="edit == 'Done'" @click="deleteWarning(tutorial.tutorialName, tutorial.imageFileName)" class="delete-button col-sm-4">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" viewBox="0 0 191.414 191.414" enable-background="new 0 0 191.414 191.414" width="512px" height="512px" class="svg-delete"><g><g><path d="M107.888,96.142l80.916-80.916c3.48-3.48,3.48-8.701,0-12.181s-8.701-3.48-12.181,0L95.707,83.961L14.791,3.045   c-3.48-3.48-8.701-3.48-12.181,0s-3.48,8.701,0,12.181l80.915,80.916L2.61,177.057c-3.48,3.48-3.48,8.701,0,12.181   c1.74,1.74,5.22,1.74,6.96,1.74s5.22,0,5.22-1.74l80.916-80.916l80.916,80.916c1.74,1.74,5.22,1.74,6.96,1.74   c1.74,0,5.22,0,5.22-1.74c3.48-3.48,3.48-8.701,0-12.181L107.888,96.142z" data-original="#000000" class="active-path" data-old_color="#000000" fill="#FFFFFF"/></g></g> </svg>
              </button> 
               <button v-else class="delete-button col-sm-4" style="opacity:0"/>

            </li>
        </ul>

                      <section v-if="deleteRecipe === true" v-on:click="cancelRecipe" class="back-prompt"></section>
              <div class="prompt"  v-if="deleteRecipe === true">
                  <div class="header-delete">
                    <h1 class="title title-delete">Do you want to delete this recipe?</h1>
                  </div>
                  <p class="text-delete">If you delete this tutorial, the tutorial will be removed directly from the Android and iOS application. There's no way to get it back.</p>
                  <button v-on:click="cancelRecipe" class="btn-cancel">Cancel</button>
                  <button v-on:click="deleteRecipeById()" class="btn-delete">Delete</button>
              </div>
      </div>
    </div>
    </div>
  </div>
</main>
</template>

<script>
import Sortable from 'sortablejs';
  import axios from 'axios';
// @ is an alias to /src
import Item from '.././components/item'
import AddItem from '.././components/addItem'
import * as firebase from "firebase";
import { mapGetters, mapActions } from "vuex";
require('firebase/firestore');

export default {
  data: function() {
    return {
      selectForDelete:'',
      deleteRecipe:false,
      tutorials: [],
      edit: 'Edit',
      jingle: ''
    };
  },
  name: 'tutorials',
  components: {
      Item,
      AddItem
  },
  computed: {
    ...mapGetters("user", ["user"]),
  },
  created() {
      firebase.firestore().collection('tutorials').get()
          .then(querySnapshot => {
              querySnapshot.docs.forEach(doc => {
                  this.tutorials.push(doc.data());
              });
          });
      console.log(this.tutorials);
      return this.tutorials;
  },
  methods: {
    deleteWarning(recipe, fileName) { 
      console.log("data: ", recipe)    
      localStorage.setItem('recipe-id', recipe);
      localStorage.setItem('img-file-name', fileName);
      this.deleteRecipe = true
    },
    cancelRecipe(){     
      this.deleteRecipe = false
    },
    deleteRecipeById() {
      let getId = localStorage.getItem('recipe-id');
      let getFileName = localStorage.getItem('img-file-name');
      console.log('local: ', getId)
      firebase.firestore().collection('tutorials').doc(getId).delete()
      this.tutorials = []
      firebase.storage().ref('images/' + getFileName).delete()
      this.deleteRecipe = false
      localStorage.removeItem('recipe-id');
      localStorage.removeItem('img-file-name');
            firebase.firestore().collection('tutorials').get()
          .then(querySnapshot => {
              querySnapshot.docs.forEach(doc => {
                  
                  this.tutorials.push(doc.data());
              });
          });
      return this.tutorials;
    },
    edittutorials () {
      if(this.edit === 'Edit')
      {
        this.edit = 'Done'
        this.jingle = 'vibrate-1'
      }else{
        this.edit = 'Edit'
        this.jingle = ''
      }
    }
  }
}
</script>

<style lang="scss">
  @import './src/style/main';
.delete-button{
    position: relative;
    float: right;
    z-index: 20;
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;
    bottom: 220px;
    width: 32px;
    padding-bottom: 4px;
    height: 32px;
    border-radius: 33px;
    right: 11px;
    border: 0;
    color: #ffffff;
    background: #ED5E68;
}
.edit-button{
  float: right;
    position: relative;
    cursor: pointer;
    background-color: #000000;
    color: white;
    text-decoration: none;
    height: 40px;
    width: 120px;
    border: none;
    border-radius: 10px;
    font-weight: bold;
    -webkit-box-shadow: 0px 0px 31px -10px #000000;
    box-shadow: 0px 0px 31px -10px #000000;
    -webkit-transition-duration: 600ms;
    transition-duration: 600ms;
    bottom: 68px;
    font-size: 14px;
    font-weight: 400;
}
#Capa_1{
    width: 100px;
    height: 100px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%);
}
.Add-info{
  width: 100%;
  margin: 0;
  padding: 0;
  position: absolute;
  bottom: -10px;
  .Add-fullname{
    left: 50%;
    transform:translateX(-50%);
    position: absolute;
    bottom: -51px;
  }
}
.add-stock{
  margin: unset!important;
}

.add-graph{
  box-shadow: unset!important;
  transition-duration: 600ms;
}
.add-graph:hover{
  box-shadow: 0px 0px 22px -8px #808080!important;
}
.prompt{
  z-index: 1000;
  transition-duration: 600ms;
  width:97%;
  max-width: 500px;
  height:250px;
  background: #ffffff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  box-shadow: 0 11px 15px -7px rgba(0,0,0,0.2), 0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12);
}
.back-prompt{
   z-index: 999;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background:rgba(0,0,0,.32);
}

.btn-delete{
  height: 40px;
  border-radius: 10px;
    border: none;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    margin-top: 20px;
    transition-duration: 600ms;
    background: #ED5E68;
    width: 109px;
    position: absolute;
    right: 17px;
    bottom: 29px;
    font-size: 14px;
}


.btn-cancel{
    height: 40px;
  border-radius: 10px;
    border: none;
    font-size: 16px;
    cursor: pointer;
    margin-top: 20px;
    transition-duration: 600ms;
    background: #ffffff;
    width: 91px;
    position: absolute;
    right: 129px;
    bottom: 29px;
    font-size: 14px;
    color: rgba(0,0,0,.54);
    -webkit-box-shadow: unset;
    box-shadow: unset;
}
.title-delete{
    position: absolute;
    top: 26px;
    left: 17px;
    color: #ffffff;
}

.btn-cancel:hover{
  box-shadow: unset;
}
.text-delete{
    position: absolute;
    left: 17px;
    top: 100px;
    line-height: 21px;
}
.header-delete{
    width: 100%;
    background: #ED5E68;
    height: 78px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
</style>

