<template>
  <div>
  <div class="whole-add-item" >
  <h1 class="title">My tutorial</h1>
  <div class="addItem">
    
    <video v-if="videoUploadEnd" controls :src="tutorial.videoURL" width="100%" class="upload-video">
      Your browser does not support HTML5 video.
    </video>
    <div class="addItem item-video">
      <div v-if="!videoUploadEnd">
        <h1 class="title title-upload">Upload tutorial video</h1>

        <input id="files" type="file" name="file" ref="videoUploadInput" accept="video/*" :multiple="false" @change="detectVideoFiles($event)" />
        <button @click="selectFile" v-if="!videoUploading" class="btn-uploads">
          Choose Video  
        </button>
      
        <div v-if="videoUploading" style="text-align:center; font-size:20px">
          {{progressVideoUpload}}%
        </div>
      </div>
      
      <div v-if="videoUploadEnd">
        <button v-if="imageUploadEnd" class="ma-0 deleteImage" dark small color="error" @click="deleteImage()">
           <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" viewBox="0 0 191.414 191.414" enable-background="new 0 0 191.414 191.414" width="512px" height="512px" class="svg-delete"><g><g><path d="M107.888,96.142l80.916-80.916c3.48-3.48,3.48-8.701,0-12.181s-8.701-3.48-12.181,0L95.707,83.961L14.791,3.045   c-3.48-3.48-8.701-3.48-12.181,0s-3.48,8.701,0,12.181l80.915,80.916L2.61,177.057c-3.48,3.48-3.48,8.701,0,12.181   c1.74,1.74,5.22,1.74,6.96,1.74s5.22,0,5.22-1.74l80.916-80.916l80.916,80.916c1.74,1.74,5.22,1.74,6.96,1.74   c1.74,0,5.22,0,5.22-1.74c3.48-3.48,3.48-8.701,0-12.181L107.888,96.142z" data-original="#000000" class="active-path" data-old_color="#000000" fill="#FFFFFF"/></g></g> </svg>
        </button>
        <img :src="tutorial.imageURL" width="220px" class="upload-image"/>
        <h1 v-if="!imageUploadEnd" class="title title-upload">Upload thumbnail</h1>

        <input v-if="!imageUploadEnd" id="files" type="file" name="file" ref="imageUploadInput" accept="image/*" :multiple="false" @change="detectImageFiles($event)" />
        <button  @click="selectImageFile" v-if="!imageUploading" class="btn-uploads">
          Choose Thumbnail  
        </button>
      
        <div v-if="imageUploading && !imageUploadEnd" style="text-align:center; font-size:20px">
          loading
        </div>
      </div>
    </div>


    <div>
      <button v-if="videoUploadEnd" class="ma-0 deleteVideo" dark small color="error" @click="deleteVideo()">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" viewBox="0 0 191.414 191.414" enable-background="new 0 0 191.414 191.414" width="512px" height="512px" class="svg-delete"><g><g><path d="M107.888,96.142l80.916-80.916c3.48-3.48,3.48-8.701,0-12.181s-8.701-3.48-12.181,0L95.707,83.961L14.791,3.045   c-3.48-3.48-8.701-3.48-12.181,0s-3.48,8.701,0,12.181l80.915,80.916L2.61,177.057c-3.48,3.48-3.48,8.701,0,12.181   c1.74,1.74,5.22,1.74,6.96,1.74s5.22,0,5.22-1.74l80.916-80.916l80.916,80.916c1.74,1.74,5.22,1.74,6.96,1.74   c1.74,0,5.22,0,5.22-1.74c3.48-3.48,3.48-8.701,0-12.181L107.888,96.142z" data-original="#000000" class="active-path" data-old_color="#000000" fill="#FFFFFF"/></g></g> </svg>
      </button>
    </div>
       
      <form pre>
      <div class="input-field">
        <label class="label-text" >Tutorial Title</label>
        <input class="input-auth inputApp" placeholder="tutorial name" type="text"  v-model="tutorial.tutorialName">
      </div>
      <div class="input-field">
        <label class="label-text" >Description</label>
        <textarea class="input-auth inputApp" style="padding:15px;" placeholder="Introduction" type="text" rows="5" v-model="tutorial.description"></textarea>
      </div>
      <div class="input-field">
        <label class="label-text" >video play time</label>
        <input class="input-auth inputApp" placeholder="250" type="number" style="width:53px; margin-right:10px;" v-model="tutorial.duration">minutes
      </div>

      <button v-if="videoUploadEnd" v-on:click="submitRecipe" style="margin-top:50px" class="btn-auth">Add tutorial</button>
    </form>
  </div>
  <section v-if="validateRecipe === true" v-on:click="cancelRecipe" class="back-prompt"></section>
  <div class="prompt"  v-if="validateRecipe === true">
      <div class="header-validate">
        <h1 class="title title-validate">Everything ready to go live?</h1>
      </div>
      <p class="text-validate">If you validate {{ tutorial.tutorialName }}, the tutorial will be put live on the Android and iOS application. This will add it to the category 'New tutorials'.</p>
      <button v-on:click="cancelRecipe" class="btn-cancel">Cancel</button>
      <button v-on:click="PushToFirestore" class="btn-validate">Validate</button>
    </div>
  </div>
    </div>
</template>

<script>
import ImageUploader from 'vue-image-upload-resize'
import * as firebase from "firebase";
import { mapGetters, mapActions } from "vuex";
require('firebase/firestore');
require('firebase/storage');

export default {
  computed: {
    ...mapGetters("user", ["user"]),
    ...mapGetters("shared", ["error"]),
  },
  data () {
    return {
      validateRecipe:false,

      progressVideoUpload: 0,
      videoUploadTask: '',
      videoUploading: false,
      videoUploadEnd: false,

      progressImageUpload: 0,
      imageUploadTask: '',
      imageUploading: false,
      imageUploadEnd: false,

      tutorial:{
        imageURL: '',
        videoURL: '',
        imageFileName: '',
        videoFileName: '',
        tutorialName:'',
        description:'',
        Duration:'',
        dateAdded: Date()
      },
    }
  },
  methods: {
    submitRecipe(e){
      e.preventDefault()       
      this.validateRecipe = true
    },

    cancelRecipe(){     
      this.validateRecipe = false
    },

    PushToFirestore(e) {
      e.preventDefault()
      console.log(this.tutorial)
      let collection = firebase.firestore().collection('tutorials').doc(this.tutorial.tutorialName).set(this.tutorial)
      this.$router.push({name: 'tutorial'});
    },
    selectFile () {
      this.$refs.videoUploadInput.click()
    },
    detectVideoFiles (e) {
      console.log("test")
      let fileList = e.target.files || e.dataTransfer.files
      Array.from(Array(fileList.length).keys()).map(x => {
        this.uploadVideo(fileList[x])
      })
    },
    uploadVideo (file) {
      console.log('data:', localStorage.getItem('uid'));
      this.tutorial.videoFileName = file.name
      this.videoUploading = true
      this.videoUploadTask = firebase.storage().ref('images/' + file.name).put(file)
    },
    deleteVideo () {
      firebase
        .storage()
        .ref('images/' + this.tutorial.videoFileName)
        .delete()
        .then(() => {
          this.videoUploading = false
          this.videoUploadEnd = false
          this.tutorial.videoURL = ''
        })
        .catch((error) => {
          console.error(`file delete error occured: ${error}`)
        })
    },


    //image upload
    selectImageFile () {
      this.$refs.imageUploadInput.click()
    },
    detectImageFiles (e) {
      console.log("test")
      let fileList = e.target.files || e.dataTransfer.files
      Array.from(Array(fileList.length).keys()).map(x => {
        this.uploadImage(fileList[x])
      })
    },
    uploadImage (file) {
      console.log('data:', localStorage.getItem('uid'));
      this.tutorial.imageFileName = file.name
      this.imageUploading = true
      this.imageUploadTask = firebase.storage().ref('images/' + file.name).put(file)
    },
    deleteImage () {
      firebase
        .storage()
        .ref('images/' + this.tutorial.imageFileName)
        .delete()
        .then(() => {
          this.imageUploading = false
          this.imageUploadEnd = false
          this.tutorial.imageURL = ''
        })
        .catch((error) => {
          console.error(`file delete error occured: ${error}`)
        })
    }
  },
  watch: {
    videoUploadTask: function () {
      this.videoUploadTask.on('state_changed', sp => {
        this.progressVideoUpload = Math.floor(sp.bytesTransferred / sp.totalBytes * 100)
      },
      null,
      () => {
        this.videoUploadTask.snapshot.ref.getDownloadURL().then(url => {
          this.videoUploadEnd = true
          this.tutorial.videoURL = url
          this.$emit(this.tutorial.videoURL, url)
        })
      })
    },
    imageUploadTask: function () {
      this.imageUploadTask.on('state_changed', sp => {
        this.progressImageUpload = Math.floor(sp.bytesTransferred / sp.totalBytes * 100)
      },
      null,
      () => {
        this.imageUploadTask.snapshot.ref.getDownloadURL().then(url => {
          this.imageUploadEnd = true
          this.tutorial.imageURL = url
          this.$emit(this.tutorial.imageURL, url)
        })
      })
    }
  }
}
</script>

<style>
.progress-bar {
  margin: 10px 0;
}
input[type="file"] {
  position: absolute;
  clip: rect(0,0,0,0);
}
.whole-add-item{
  margin-left: 250px;
  max-width: 1000px;
  width: 100%;
  margin: auto
}
.addItem{
    background: #ffffff;
    padding: 35px;
    min-height: 175px;
    border-radius: 10px;
    margin-bottom: 100px;
    position: relative;
}
.item-video{
  background:#f6f6fc;
  margin-bottom: 8px;
}
.upload-video{
  border-radius:10px;
}
.upload-image{
  border-radius:10px;
    position: relative;
  left: 50%;
  transform: translateX(-50%);
}
.ma-0{
    position: absolute;
    top: 47px;
    width: 32px;
    padding-bottom: 4px;
    height: 32px;
    border-radius: 33px;
    right: 47px;
    border: 0;
    color: #ffffff;
    background: #ED5E68;
}
.deleteImage{
    position: absolute;
    left: calc(50% + 79px);
    transform: translateX(-50%);
    z-index: 2000;
}
.title{
  font-size: 32px;
  margin-bottom:20px;
  margin-bottom: 33px;
}
.btn-uploads{
    border: 0;
    background: #000000;
    height: 49px;
    width: 174px;
    position: relative;
    border-radius: 10px;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
    left: 50%;
    transform: translateX(-50%);
}
.upload-an-image{
  margin-left:124px; 
  max-width: 415px;
  width: 90%;
  height: 174px;
  z-index: 20;
}
.title-upload{
  text-align: center;
}
button:focus {outline:0;}
.dynamic-input{
  width: 100%
}
.ingredient-input{
  width: 50%;
  min-width: 400px;
}
.step{
    background: #000000;
    color: #ffffff;
    width: 25px;
    height: 25px;
    padding: 0;
    margin: 0;
    border-radius: 25px;
   
}
 .step p{
    margin: auto;
    text-align: center;
    padding-top: 5px;
    font-size: 14px;
    }
    textarea{
      height: unset!important
    }
    tbody{
      margin-top:20px;
    }
    .btn-remove{
       background: #ED5E68;
        color: #ffffff;

    padding:12px 9px 9px 9px;
    margin-left: 13px;
    border-radius: 25px;
    }
    .svg-delete{
    width: 15px;
    padding-top: 4px;
    height: 16px;
    }
    .btn-add{
      border-style: none;
      color: #000000;
      font-size: 15px;
      padding: 0;
      margin-top: 20px;
      text-decoration: underline;
      cursor: pointer;
    }

    .ingredient{
      width: 90%;
      padding-right: 10px;
    }
    .table tr>td {
  padding-bottom: 5px;
}
.prompt{
  transition-duration: 600ms;
  width:97%;
  max-width: 500px;
  height:250px;
  background: #ffffff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  box-shadow: 0 11px 15px -7px rgba(0,0,0,0.2), 0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12);
}
.back-prompt{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background:rgba(0,0,0,.32);
}

.btn-validate{
  height: 40px;
    border-radius: 10px;
    border: none;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    margin-top: 20px;
    -webkit-transition-duration: 600ms;
    transition-duration: 600ms;
    background: #000000;
    width: 109px;
    position: absolute;
    right: 17px;
    bottom: 29px;
    font-size: 14px;
}
.title-validate{
    position: absolute;
    top: 26px;
    left: 17px;
    color: #ffffff;
}

.btn-cancel{
    background: #ffffff;
    width: 91px;
    position: absolute;
    right: 129px;
    bottom: 29px;
    font-size: 14px;
    color: rgba(0,0,0,.54);
    -webkit-box-shadow: unset;
    box-shadow: unset;
}
.btn-cancel:hover{
    box-shadow: unset;
}
.text-validate{
    position: absolute;
    left: 17px;
    top: 100px;
    line-height: 21px;
}
.header-validate{
    width: 100%;
    background: #000000;
    height: 78px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
</style>