<template>
    <router-link v-bind:to="link" class="link ">
      <div class="graph">
         <img v-bind:src="img" class="image"/>
      </div>
       <div class="stock-name"><p style="text-decoration: none;">{{ name }}</p></div>
        </router-link>
</template>

<script>
// @ is an alias to /src


export default {
  props: ['name', 'img', 'link'],
  data() {
    return {
      name: this.props.name,
      img: this.props.img,
      link: this.props.link
    };
  },
  name: 'microgreens',
  components: {

  }
}
</script>

<style lang="scss">
  @import './src/style/main';
  .image{
    min-height: 100%;
    width: 100%;
    position: relative;
    left: 50%;
    top:50%;
    transform:translate(-50%, -50%);

  }
  .link{
    text-decoration: none;
  }
</style>

