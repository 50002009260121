<template>
<div>

    <div class="whole-add-item">
        <h1 class="title">My microgreen</h1>
        <div class="addItem" style="margin-bottom: 30px;">

            <div v-if="!uploadEnd1" class="addItem item-video">
                <h1 class="title title-upload">Upload Hero image</h1>

                <input id="files" type="file" name="file" ref="uploadInput1" accept="image/*" :multiple="false" @change="detectFiles1($event)" />
                <button @click="selectFile1" v-if="!uploading1" class="btn-upload">
                    Choose Image
                </button>

                <div v-if="uploading1" style="text-align:center; font-size:20px">
                    {{progressUpload1}}%
                </div>
            </div>
            <img :src="microgreen.downloadURL1" width="100%" class="upload-image" style="margin-bottom: 27px;" />
            <div>
                <button v-if="uploadEnd1" class="del-1" dark small color="error" @click="deleteImage1()">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" viewBox="0 0 191.414 191.414" enable-background="new 0 0 191.414 191.414" width="512px" height="512px" class="svg-delete">
                        <g>
                            <g>
                                <path d="M107.888,96.142l80.916-80.916c3.48-3.48,3.48-8.701,0-12.181s-8.701-3.48-12.181,0L95.707,83.961L14.791,3.045   c-3.48-3.48-8.701-3.48-12.181,0s-3.48,8.701,0,12.181l80.915,80.916L2.61,177.057c-3.48,3.48-3.48,8.701,0,12.181   c1.74,1.74,5.22,1.74,6.96,1.74s5.22,0,5.22-1.74l80.916-80.916l80.916,80.916c1.74,1.74,5.22,1.74,6.96,1.74   c1.74,0,5.22,0,5.22-1.74c3.48-3.48,3.48-8.701,0-12.181L107.888,96.142z" data-original="#000000" class="active-path" data-old_color="#000000" fill="#FFFFFF" />
                            </g>
                        </g>
                    </svg>
                </button>
            </div>
            <ul style="display:flex; position:relative">
                <li style="padding-right:15px;width: 100%;position:relative">
                    <div v-if="!uploadEnd2" class="addItem item-video">
                        <h1 class="title title-upload">Upload second image</h1>

                        <input id="files" type="file" name="file" ref="uploadInput2" accept="image/*" :multiple="false" @change="detectFiles2($event)" />
                        <button @click="selectFile2" v-if="!uploading2" class="btn-upload">
                            Choose Image
                        </button>

                        <div v-if="uploading2" style="text-align:center; font-size:20px">
                            {{progressUpload2}}%
                        </div>
                    </div>
                    <div>
                        <button v-if="uploadEnd2" class="del" style="right:28px;" dark small color="error" @click="deleteImage2()">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" viewBox="0 0 191.414 191.414" enable-background="new 0 0 191.414 191.414" width="512px" height="512px" class="svg-delete">
                                <g>
                                    <g>
                                        <path d="M107.888,96.142l80.916-80.916c3.48-3.48,3.48-8.701,0-12.181s-8.701-3.48-12.181,0L95.707,83.961L14.791,3.045   c-3.48-3.48-8.701-3.48-12.181,0s-3.48,8.701,0,12.181l80.915,80.916L2.61,177.057c-3.48,3.48-3.48,8.701,0,12.181   c1.74,1.74,5.22,1.74,6.96,1.74s5.22,0,5.22-1.74l80.916-80.916l80.916,80.916c1.74,1.74,5.22,1.74,6.96,1.74   c1.74,0,5.22,0,5.22-1.74c3.48-3.48,3.48-8.701,0-12.181L107.888,96.142z" data-original="#000000" class="active-path" data-old_color="#000000" fill="#FFFFFF" />
                                    </g>
                                </g>
                            </svg>
                        </button>
                    </div>
                    <img :src="microgreen.downloadURL2" width="100%" class="upload-image img-half" />

                </li>

                <li style="padding-left:15px;width: 100%; position:relative">
                    <div v-if="!uploadEnd3" class="addItem item-video">
                        <h1 class="title title-upload">Upload third image</h1>

                        <input id="files" type="file" name="file" ref="uploadInput3" accept="image/*" :multiple="false" @change="detectFiles3($event)" />
                        <button @click="selectFile3" v-if="!uploading3" class="btn-upload">
                            Choose Image
                        </button>

                        <div v-if="uploading3" style="text-align:center; font-size:20px">
                            {{progressUpload3}}%
                        </div>
                    </div>
                    <div>
                        <button v-if="uploadEnd3" class="del" dark small color="error" @click="deleteImage3()">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" viewBox="0 0 191.414 191.414" enable-background="new 0 0 191.414 191.414" width="512px" height="512px" class="svg-delete">
                                <g>
                                    <g>
                                        <path d="M107.888,96.142l80.916-80.916c3.48-3.48,3.48-8.701,0-12.181s-8.701-3.48-12.181,0L95.707,83.961L14.791,3.045   c-3.48-3.48-8.701-3.48-12.181,0s-3.48,8.701,0,12.181l80.915,80.916L2.61,177.057c-3.48,3.48-3.48,8.701,0,12.181   c1.74,1.74,5.22,1.74,6.96,1.74s5.22,0,5.22-1.74l80.916-80.916l80.916,80.916c1.74,1.74,5.22,1.74,6.96,1.74   c1.74,0,5.22,0,5.22-1.74c3.48-3.48,3.48-8.701,0-12.181L107.888,96.142z" data-original="#000000" class="active-path" data-old_color="#000000" fill="#FFFFFF" />
                                    </g>
                                </g>
                            </svg>
                        </button>
                    </div>
                    <img :src="microgreen.downloadURL3" width="100%" class="upload-image img-half" style="float: right;" />

                </li>

                <!-- BEGIN -->
                <li style="padding-left:15px;width: 100%; position:relative">
                    <div v-if="!uploadEnd4" class="addItem item-video">
                        <h1 class="title title-upload">Upload third image</h1>

                        <input id="files" type="file" name="file" ref="uploadInput4" accept="image/*" :multiple="false" @change="detectFiles4($event)" />
                        <button @click="selectFile4" v-if="!uploading4" class="btn-upload">
                            Choose Image
                        </button>

                        <div v-if="uploading4" style="text-align:center; font-size:20px">
                            {{progressUpload4}}%
                        </div>
                    </div>
                    <div>
                        <button v-if="uploadEnd4" class="del" dark small color="error" @click="deleteImage4()">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" viewBox="0 0 191.414 191.414" enable-background="new 0 0 191.414 191.414" width="512px" height="512px" class="svg-delete">
                                <g>
                                    <g>
                                        <path d="M107.888,96.142l80.916-80.916c3.48-3.48,3.48-8.701,0-12.181s-8.701-3.48-12.181,0L95.707,83.961L14.791,3.045   c-3.48-3.48-8.701-3.48-12.181,0s-3.48,8.701,0,12.181l80.915,80.916L2.61,177.057c-3.48,3.48-3.48,8.701,0,12.181   c1.74,1.74,5.22,1.74,6.96,1.74s5.22,0,5.22-1.74l80.916-80.916l80.916,80.916c1.74,1.74,5.22,1.74,6.96,1.74   c1.74,0,5.22,0,5.22-1.74c3.48-3.48,3.48-8.701,0-12.181L107.888,96.142z" data-original="#000000" class="active-path" data-old_color="#000000" fill="#FFFFFF" />
                                    </g>
                                </g>
                            </svg>
                        </button>
                    </div>
                    <img :src="microgreen.downloadURL4" width="100%" class="upload-image img-half" style="float: right;" />

                </li>

                <li style="padding-left:15px;width: 100%; position:relative">
                    <div v-if="!uploadEnd5" class="addItem item-video">
                        <h1 class="title title-upload">Upload third image</h1>

                        <input id="files" type="file" name="file" ref="uploadInput5" accept="image/*" :multiple="false" @change="detectFiles5($event)" />
                        <button @click="selectFile5" v-if="!uploading5" class="btn-upload">
                            Choose Image
                        </button>

                        <div v-if="uploading5" style="text-align:center; font-size:20px">
                            {{progressUpload5}}%
                        </div>
                    </div>
                    <div>
                        <button v-if="uploadEnd5" class="del" dark small color="error" @click="deleteImage5()">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" viewBox="0 0 191.414 191.414" enable-background="new 0 0 191.414 191.414" width="512px" height="512px" class="svg-delete">
                                <g>
                                    <g>
                                        <path d="M107.888,96.142l80.916-80.916c3.48-3.48,3.48-8.701,0-12.181s-8.701-3.48-12.181,0L95.707,83.961L14.791,3.045   c-3.48-3.48-8.701-3.48-12.181,0s-3.48,8.701,0,12.181l80.915,80.916L2.61,177.057c-3.48,3.48-3.48,8.701,0,12.181   c1.74,1.74,5.22,1.74,6.96,1.74s5.22,0,5.22-1.74l80.916-80.916l80.916,80.916c1.74,1.74,5.22,1.74,6.96,1.74   c1.74,0,5.22,0,5.22-1.74c3.48-3.48,3.48-8.701,0-12.181L107.888,96.142z" data-original="#000000" class="active-path" data-old_color="#000000" fill="#FFFFFF" />
                                    </g>
                                </g>
                            </svg>
                        </button>
                    </div>
                    <img :src="microgreen.downloadURL5" width="100%" class="upload-image img-half" style="float: right;" />

                </li>

                <li style="padding-left:15px;width: 100%; position:relative">
                    <div v-if="!uploadEnd6" class="addItem item-video">
                        <h1 class="title title-upload">Upload third image</h1>

                        <input id="files" type="file" name="file" ref="uploadInput6" accept="image/*" :multiple="false" @change="detectFiles6($event)" />
                        <button @click="selectFile6" v-if="!uploading6" class="btn-upload">
                            Choose Image
                        </button>

                        <div v-if="uploading6" style="text-align:center; font-size:20px">
                            {{progressUpload6}}%
                        </div>
                    </div>
                    <div>
                        <button v-if="uploadEnd6" class="del" dark small color="error" @click="deleteImage6()">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" viewBox="0 0 191.414 191.414" enable-background="new 0 0 191.414 191.414" width="512px" height="512px" class="svg-delete">
                                <g>
                                    <g>
                                        <path d="M107.888,96.142l80.916-80.916c3.48-3.48,3.48-8.701,0-12.181s-8.701-3.48-12.181,0L95.707,83.961L14.791,3.045   c-3.48-3.48-8.701-3.48-12.181,0s-3.48,8.701,0,12.181l80.915,80.916L2.61,177.057c-3.48,3.48-3.48,8.701,0,12.181   c1.74,1.74,5.22,1.74,6.96,1.74s5.22,0,5.22-1.74l80.916-80.916l80.916,80.916c1.74,1.74,5.22,1.74,6.96,1.74   c1.74,0,5.22,0,5.22-1.74c3.48-3.48,3.48-8.701,0-12.181L107.888,96.142z" data-original="#000000" class="active-path" data-old_color="#000000" fill="#FFFFFF" />
                                    </g>
                                </g>
                            </svg>
                        </button>
                    </div>
                    <img :src="microgreen.downloadURL6" width="100%" class="upload-image img-half" style="float: right;" />

                </li>
                <!-- EINDE -->

            </ul>

        </div>
        <div class="addItem">
            <form pre>
                <div class="input-field">
                    <label class="label-text">Microgreen name</label>
                    <input class="input-auth inputApp" placeholder="Microgreen name" type="text" v-model="microgreen.microgreenName">
                </div>
                <div class="input-field">
                    <label class="label-text">Genus species</label>
                    <input class="input-auth inputApp" placeholder="Brassica rapa" type="text" v-model="microgreen.genusSpecies">
                </div>
                <div class="input-field">
                    <label class="label-text">Taste description</label>
                    <input class="input-auth inputApp" placeholder="verry mild taste" type="text" v-model="microgreen.tasteDescription">
                </div>
                <div class="input-field">
                    <label class="label-text">Microgreen description</label>
                    <textarea class="input-auth inputApp" style="padding:15px;" placeholder="Introduction" type="text" rows="5" v-model="microgreen.description"></textarea>
                </div>
                <div class="input-field">
                    <label class="label-text">Grow time</label>
                    <input class="input-auth inputApp" placeholder="7" type="number" style="width:53px; margin-right:10px;" v-model="microgreen.growTime">days
                </div>
                <div class="input-field">
                    <label class="label-text">germinating time</label>
                    <input class="input-auth inputApp" placeholder="3" type="number" style="width:53px; margin-right:10px;" v-model="microgreen.germinatingTime">days
                </div>
                <div class="input-field">
                    <label class="label-text">Total grow time</label>
                    <input class="input-auth inputApp" placeholder="10" type="number" style="width:53px; margin-right:10px;" v-model="microgreen.growTimeTotal">days
                </div>

                <table class="input-field ingredient-input">
                    <thead>
                        <tr>
                            <label class="label-text">Which recipe contains {{microgreen.microgreenName}}?</label>
                        </tr>
                    </thead>
                    <tbody class="table">
                        <tr v-for="(row, index) in microgreen.recipes" :key="index">
                            <td width="95%">
                                <select v-model="row.recipeName" class="input-auth inputApp ingredient">
                                    <option value="" disabled selected>Select a recipe</option>
                                    <option v-for="recipe in allRecipes" :key="recipe" :value="recipe.recipeName">{{recipe.recipeName}}</option>
                                </select>
                            </td>
                            <td width="5%">
                                <a v-on:click="removeElement(index);" style="cursor: pointer;font-size:10px;" class="btn-remove">

                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" viewBox="0 0 191.414 191.414" enable-background="new 0 0 191.414 191.414" width="512px" height="512px" class="svg-delete">
                                        <g>
                                            <g>
                                                <path d="M107.888,96.142l80.916-80.916c3.48-3.48,3.48-8.701,0-12.181s-8.701-3.48-12.181,0L95.707,83.961L14.791,3.045   c-3.48-3.48-8.701-3.48-12.181,0s-3.48,8.701,0,12.181l80.915,80.916L2.61,177.057c-3.48,3.48-3.48,8.701,0,12.181   c1.74,1.74,5.22,1.74,6.96,1.74s5.22,0,5.22-1.74l80.916-80.916l80.916,80.916c1.74,1.74,5.22,1.74,6.96,1.74   c1.74,0,5.22,0,5.22-1.74c3.48-3.48,3.48-8.701,0-12.181L107.888,96.142z" data-original="#000000" class="active-path" data-old_color="#000000" fill="#FFFFFF" />
                                            </g>
                                        </g>
                                    </svg></a>
                            </td>

                        </tr>
                    </tbody>
                </table>
                <div>
                    <button class="btn-add" v-on:click="addRow">Add a recipe</button>
                </div>

                <table class="input-field ingredient-input">
                    <thead>

                        <label class="label-text">Which nutrients contains {{microgreen.microgreenName}}?</label>

                    </thead>
                    <tbody class="table">
                        <tr v-for="(row, index) in microgreen.nutrients" :key="index">
                            <td width="10%">
                                <input class="input-auth inputApp" placeholder="36" type="number" style="width:60%; margin-right:10px;" v-model="row.percentage">%
                            </td>
                            <td width="85%">
                                <input class="input-auth inputApp" placeholder="nutrient" type="text" style="width:100%; margin-right:10px;" v-model="row.nutrientName">
                            </td>
                            <td width="5%">
                                <a v-on:click="removeInstruction(index);" style="cursor: pointer;font-size:10px;" class="btn-remove">

                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" viewBox="0 0 191.414 191.414" enable-background="new 0 0 191.414 191.414" width="512px" height="512px" class="svg-delete">
                                        <g>
                                            <g>
                                                <path d="M107.888,96.142l80.916-80.916c3.48-3.48,3.48-8.701,0-12.181s-8.701-3.48-12.181,0L95.707,83.961L14.791,3.045   c-3.48-3.48-8.701-3.48-12.181,0s-3.48,8.701,0,12.181l80.915,80.916L2.61,177.057c-3.48,3.48-3.48,8.701,0,12.181   c1.74,1.74,5.22,1.74,6.96,1.74s5.22,0,5.22-1.74l80.916-80.916l80.916,80.916c1.74,1.74,5.22,1.74,6.96,1.74   c1.74,0,5.22,0,5.22-1.74c3.48-3.48,3.48-8.701,0-12.181L107.888,96.142z" data-original="#000000" class="active-path" data-old_color="#000000" fill="#FFFFFF" />
                                            </g>
                                        </g>
                                    </svg></a>
                            </td>

                        </tr>
                    </tbody>
                </table>
                <div>
                    <button class="btn-add" v-on:click="addInstruction">Add a nutrient</button>
                </div>

                <button v-on:click="submitmicrogreen" style="margin-top:50px" class="btn-auth">Add microgreen</button>
            </form>

        </div>
        <section v-if="validatemicrogreen === true" v-on:click="cancelmicrogreen" class="back-prompt"></section>
        <div class="prompt" v-if="validatemicrogreen === true">
            <div class="header-validate">
                <h1 class="title title-validate">Everything ready to go live?</h1>
            </div>
            <p class="text-validate">If you validate {{ microgreen.microgreenName }}, the microgreen will be put live on the Android and iOS application. This will add it to the category 'New microgreens'.</p>
            <button v-on:click="cancelmicrogreen" class="btn-cancel">Cancel</button>
            <button v-on:click="PushToFirestore" class="btn-validate">Validate</button>
        </div>
    </div>
</div>
</template>

<script>
import * as firebase from "firebase";
import {
    mapGetters,
    mapActions
} from "vuex";
require('firebase/firestore');
require('firebase/storage');

export default {
    computed: {
        ...mapGetters("user", ["user"]),
        ...mapGetters("shared", ["error"]),
    },
    data() {
        return {
            validatemicrogreen: false,
            progressUpload1: 0,
            progressUpload2: 0,
            progressUpload3: 0,
            progressUpload4: 0,
            progressUpload5: 0,
            progressUpload6: 0,

            uploadTask1: '',
            uploadTask2: '',
            uploadTask3: '',
            uploadTask4: '',
            uploadTask5: '',
            uploadTask6: '',

            uploading1: false,
            uploading2: false,
            uploading3: false,
            uploading4: false,
            uploading5: false,
            uploading6: false,

            uploadEnd1: false,
            uploadEnd2: false,
            uploadEnd3: false,
            uploadEnd4: false,
            uploadEnd5: false,
            uploadEnd6: false,

            microgreen: {
                downloadURL1: '',
                downloadURL2: '',
                downloadURL3: '',
                downloadURL4: '',
                downloadURL5: '',
                downloadURL6: '',

                imageFileName1: '',
                imageFileName2: '',
                imageFileName3: '',
                imageFileName4: '',
                imageFileName5: '',
                imageFileName6: '',

                microgreenName: '',
                nutrients: [],
                recipes: [],
                germinatingTime: '',
                growTime: '',
                growTimeTotal: '',
                description: '',
                genusSpecies: '',
                tasteDescription: '',
                dateAdded: Date()
            },
            allRecipes: []
        }
    },
    methods: {
        submitmicrogreen(e) {
            e.preventDefault()
            this.validatemicrogreen = true
        },

        cancelmicrogreen() {
            this.validatemicrogreen = false
        },

        addInstruction(e) {
            e.preventDefault()
            this.microgreen.nutrients.push({
                nutrientName: '',
                percentage: ''
            });
        },
        removeInstruction: function (index) {
            this.microgreen.nutrients.splice(index, 1);
        },

        addRow(e) {
            e.preventDefault()
            this.microgreen.recipes.push({
                recipeName: ""
            });
        },
        removeElement: function (index) {
            this.microgreen.recipes.splice(index, 1);
        },
        PushToFirestore(e) {
            e.preventDefault()
            console.log(this.microgreen)
            let collection = firebase.firestore().collection('microgreens').doc(this.microgreen.microgreenName).set(this.microgreen)
            this.$router.push({
                name: 'microgreens'
            });
        },
        selectFile1() {
            this.$refs.uploadInput1.click()
        },
        selectFile2() {
            this.$refs.uploadInput2.click()
        },
        selectFile3() {
            this.$refs.uploadInput3.click()
        },
        selectFile4() {
            this.$refs.uploadInput4.click()
        },
        selectFile5() {
            this.$refs.uploadInput5.click()
        },
        selectFile6() {
            this.$refs.uploadInput6.click()
        },
        detectFiles1(e) {
            console.log("test")
            let fileList = e.target.files || e.dataTransfer.files
            Array.from(Array(fileList.length).keys()).map(x => {
                this.upload1(fileList[x])
            })
        },
        detectFiles2(e) {
            console.log("test")
            let fileList = e.target.files || e.dataTransfer.files
            Array.from(Array(fileList.length).keys()).map(x => {
                this.upload2(fileList[x])
            })
        },
        detectFiles3(e) {
            console.log("test")
            let fileList = e.target.files || e.dataTransfer.files
            Array.from(Array(fileList.length).keys()).map(x => {
                this.upload3(fileList[x])
            })
        },
        detectFiles4(e) {
            console.log("test")
            let fileList = e.target.files || e.dataTransfer.files
            Array.from(Array(fileList.length).keys()).map(x => {
                this.upload4(fileList[x])
            })
        },
        detectFiles5(e) {
            console.log("test")
            let fileList = e.target.files || e.dataTransfer.files
            Array.from(Array(fileList.length).keys()).map(x => {
                this.upload5(fileList[x])
            })
        },
        detectFiles6(e) {
            console.log("test")
            let fileList = e.target.files || e.dataTransfer.files
            Array.from(Array(fileList.length).keys()).map(x => {
                this.upload6(fileList[x])
            })
        },
        upload1(file) {

            console.log('????? data:', localStorage.getItem('uid'));
            this.microgreen.imageFileName1 = file.name
            this.uploading = true
            this.uploadTask1 = firebase.storage().ref('images/' + file.name).put(file)
        },
        upload2(file) {

            console.log('????? data:', localStorage.getItem('uid'));
            this.microgreen.imageFileName2 = file.name
            this.uploading = true
            this.uploadTask2 = firebase.storage().ref('images/' + file.name).put(file)
        },
        upload3(file) {

            console.log('????? data:', localStorage.getItem('uid'));
            this.microgreen.imageFileName3 = file.name
            this.uploading = true
            this.uploadTask3 = firebase.storage().ref('images/' + file.name).put(file)
        },
        upload4(file) {

            console.log('????? data:', localStorage.getItem('uid'));
            this.microgreen.imageFileName4 = file.name
            this.uploading = true
            this.uploadTask4 = firebase.storage().ref('images/' + file.name).put(file)
        },
        upload5(file) {

            console.log('????? data:', localStorage.getItem('uid'));
            this.microgreen.imageFileName5 = file.name
            this.uploading = true
            this.uploadTask5 = firebase.storage().ref('images/' + file.name).put(file)
        },
        upload6(file) {

            console.log('????? data:', localStorage.getItem('uid'));
            this.microgreen.imageFileName6 = file.name
            this.uploading = true
            this.uploadTask6 = firebase.storage().ref('images/' + file.name).put(file)
        },
        deleteImage1() {
            firebase
                .storage()
                .ref('images/' + this.microgreen.imageFileName1)
                .delete()
                .then(() => {
                    this.uploading1 = false
                    this.uploadEnd1 = false
                    this.microgreen.downloadURL1 = ''
                })
                .catch((error) => {
                    console.error(`file delete error occured: ${error}`)
                })
        },
        deleteImage2() {
            firebase
                .storage()
                .ref('images/' + this.microgreen.imageFileName2)
                .delete()
                .then(() => {
                    this.uploading2 = false
                    this.uploadEnd2 = false
                    this.microgreen.downloadURL2 = ''
                })
                .catch((error) => {
                    console.error(`file delete error occured: ${error}`)
                })
        },
        deleteImage3() {
            firebase
                .storage()
                .ref('images/' + this.microgreen.imageFileName3)
                .delete()
                .then(() => {
                    this.uploading3 = false
                    this.uploadEnd3 = false
                    this.microgreen.downloadURL3 = ''
                })
                .catch((error) => {
                    console.error(`file delete error occured: ${error}`)
                })
        },
        deleteImage4() {
            firebase
                .storage()
                .ref('images/' + this.microgreen.imageFileName4)
                .delete()
                .then(() => {
                    this.uploading4 = false
                    this.uploadEnd4 = false
                    this.microgreen.downloadURL4 = ''
                })
                .catch((error) => {
                    console.error(`file delete error occured: ${error}`)
                })
        },
        deleteImage5() {
            firebase
                .storage()
                .ref('images/' + this.microgreen.imageFileName5)
                .delete()
                .then(() => {
                    this.uploading5 = false
                    this.uploadEnd5 = false
                    this.microgreen.downloadURL5 = ''
                })
                .catch((error) => {
                    console.error(`file delete error occured: ${error}`)
                })
        },
        deleteImage6() {
            firebase
                .storage()
                .ref('images/' + this.microgreen.imageFileName6)
                .delete()
                .then(() => {
                    this.uploading6 = false
                    this.uploadEnd6 = false
                    this.microgreen.downloadURL6 = ''
                })
                .catch((error) => {
                    console.error(`file delete error occured: ${error}`)
                })
        }
    },
    watch: {
        uploadTask1: function () {
            this.uploadTask1.on('state_changed', sp => {
                    this.progressUpload1 = Math.floor(sp.bytesTransferred / sp.totalBytes * 100)
                },
                null,
                () => {
                    this.uploadTask1.snapshot.ref.getDownloadURL().then(url => {
                        this.uploadEnd1 = true
                        this.microgreen.downloadURL1 = url
                        this.$emit(this.microgreen.downloadURL1, url)
                    })
                })
        },
        uploadTask2: function () {
            this.uploadTask2.on('state_changed', sp => {
                    this.progressUpload2 = Math.floor(sp.bytesTransferred / sp.totalBytes * 100)
                },
                null,
                () => {
                    this.uploadTask2.snapshot.ref.getDownloadURL().then(url => {
                        this.uploadEnd2 = true
                        this.microgreen.downloadURL2 = url
                        this.$emit(this.microgreen.downloadURL2, url)
                    })
                })
        },
        uploadTask3: function () {
            this.uploadTask3.on('state_changed', sp => {
                    this.progressUpload3 = Math.floor(sp.bytesTransferred / sp.totalBytes * 100)
                },
                null,
                () => {
                    this.uploadTask3.snapshot.ref.getDownloadURL().then(url => {
                        this.uploadEnd3 = true
                        this.microgreen.downloadURL3 = url
                        this.$emit(this.microgreen.downloadURL3, url)
                    })
                })
        },
        uploadTask4: function () {
            this.uploadTask4.on('state_changed', sp => {
                    this.progressUpload4 = Math.floor(sp.bytesTransferred / sp.totalBytes * 100)
                },
                null,
                () => {
                    this.uploadTask4.snapshot.ref.getDownloadURL().then(url => {
                        this.uploadEnd4 = true
                        this.microgreen.downloadURL4 = url
                        this.$emit(this.microgreen.downloadURL4, url)
                    })
                })
        },
        uploadTask5: function () {
            this.uploadTask5.on('state_changed', sp => {
                    this.progressUpload5 = Math.floor(sp.bytesTransferred / sp.totalBytes * 100)
                },
                null,
                () => {
                    this.uploadTask5.snapshot.ref.getDownloadURL().then(url => {
                        this.uploadEnd5 = true
                        this.microgreen.downloadURL5 = url
                        this.$emit(this.microgreen.downloadURL5, url)
                    })
                })
        },
        uploadTask6: function () {
            this.uploadTask6.on('state_changed', sp => {
                    this.progressUpload6 = Math.floor(sp.bytesTransferred / sp.totalBytes * 100)
                },
                null,
                () => {
                    this.uploadTask6.snapshot.ref.getDownloadURL().then(url => {
                        this.uploadEnd6 = true
                        this.microgreen.downloadURL6 = url
                        this.$emit(this.microgreen.downloadURL6, url)
                    })
                })
        },
    },
    created() {
        firebase.firestore().collection('recipes').get()
            .then(querySnapshot => {
                querySnapshot.docs.forEach(doc => {
                    this.allRecipes.push(doc.data());
                });
            });
        console.log(this.allRecipes);
        return this.allRecipes;
    },
}
</script>

<style>
.progress-bar {
    margin: 10px 0;
}

input[type="file"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
}

.whole-add-item {
    margin-left: 250px;
    max-width: 1000px;
    width: 100%;
    margin: auto
}

.addItem {
    background: #ffffff;
    padding: 35px;
    min-height: 179px;
    border-radius: 10px;
    margin-bottom: 100px;
    position: relative;
}

.upload-image {
    border-radius: 10px;
}

.item-video {
    background: #f6f6fc;
    margin-bottom: 8px;
    min-height: 175px;
}

.del {
    position: absolute;
    top: 11px;
    width: 32px;
    padding-bottom: 4px;
    height: 32px;
    border-radius: 33px;
    right: 15px;
    border: 0;
    color: #ffffff;
    background: #ED5E68;
}

.title {
    font-size: 32px;
    margin-bottom: 20px;
    margin-bottom: 33px;
}

.btn-upload {
    border: 0;
    background: #000000;
    height: 49px;
    width: 174px;
    border-radius: 10px;
    position: absolute;
    left: 50%;
    bottom: 35px;
    transform: translateX(-50%);
    color: #fff;
    font-size: 14px;
    cursor: pointer;
}

.upload-an-image {
    position: absolute;
    margin-left: 124px;
    max-width: 415px;
    width: 90%;
    height: 174px;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.title-upload {
    text-align: center;
}

button:focus {
    outline: 0;
}

.dynamic-input {
    width: 100%
}

.ingredient-input {
    width: 50%;
    min-width: 400px;
}

.step {
    background: #000000;
    color: #ffffff;
    width: 25px;
    height: 25px;
    padding: 0;
    margin: 0;
    border-radius: 25px;

}

.step p {
    margin: auto;
    text-align: center;
    padding-top: 5px;
    font-size: 14px;
}

textarea {
    height: unset !important
}

tbody {
    margin-top: 20px;
}

.btn-remove {
    background: #ED5E68;
    color: #ffffff;

    padding: 12px 9px 9px 9px;
    margin-left: 13px;
    border-radius: 25px;
}

.svg-delete {
    width: 15px;
    padding-top: 4px;
    height: 16px;
}

.btn-add {
    border-style: none;
    color: #000000;
    font-size: 15px;
    padding: 0;
    margin-top: 20px;
    text-decoration: underline;
    cursor: pointer;
}

.ingredient {
    width: 90%;
    padding-right: 10px;
}

.table tr>td {
    padding-bottom: 5px;
}

.prompt {
    transition-duration: 600ms;
    width: 97%;
    max-width: 500px;
    height: 250px;
    background: #ffffff;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.back-prompt {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, .32);
}

.btn-validate {
    height: 40px;
    border-radius: 10px;
    border: none;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    margin-top: 20px;
    -webkit-transition-duration: 600ms;
    transition-duration: 600ms;
    background: #000000;
    width: 109px;
    position: absolute;
    right: 17px;
    bottom: 29px;
    font-size: 14px;
}

.title-validate {
    position: absolute;
    top: 26px;
    left: 17px;
    color: #ffffff;
}

.btn-cancel {
    background: #ffffff;
    width: 91px;
    position: absolute;
    right: 129px;
    bottom: 29px;
    font-size: 14px;
    color: rgba(0, 0, 0, .54);
    -webkit-box-shadow: unset;
    box-shadow: unset;
}

.btn-cancel:hover {
    box-shadow: unset;
}

.text-validate {
    position: absolute;
    left: 17px;
    top: 100px;
    line-height: 21px;
}

.header-validate {
    width: 100%;
    background: #000000;
    height: 78px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.img-half {
    width: 100%;
    float: left;
    position: unset !important;
    transform: unset !important;
}

.del-1 {
    position: absolute;
    top: 49px;
    width: 32px;
    padding-bottom: 4px;
    height: 32px;
    border-radius: 33px;
    right: 52px;
    border: 0;
    color: #ffffff;
    background: #ED5E68;
}
</style>
