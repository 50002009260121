<template>
<main>
    <div class="c-top">
        <ul>
            <li>
                <router-link to="/" class="list">
                    <p>Overview</p>
                    <div class="c-bullet"></div>
                </router-link>
            </li>

            <li>
                <router-link to="/microgreens" class="list">
                    <p>Microgreens</p>
                    <div class="c-bullet"></div>
                </router-link>
            </li>
            <li>
                <router-link to="/recipes" class="list">
                    <p>Recipes</p>
                    <div class="c-bullet"></div>
                </router-link>
            </li>
            <li>
                <router-link to="/tutorial" class="list">
                    <p>Tutorials</p>
                    <div class="c-bullet"></div>
                </router-link>
            </li>
        </ul>
    </div>
    <div class="home">
        <div class="home-wrapper">
        <h1 class="headtitle">Mother App Dashboard</h1>
        <div class="o-grid home-grid">
            <div class="addItem col-sm-4">
                <h2 class="title">All Users</h2>
                <h2 class="counter">{{ allUserCount }}</h2>
                <router-link to="/all-users" class="link-users">Show All Users</router-link>
                <p></p>
            </div>
            <div class="addItem col-sm-4">
                <h2 class="title">All Admin Users</h2>
                <h2 class="counter">{{ adminUsers }}</h2>
                <router-link to="/admin-users" class="link-users">Show Admin Users</router-link>
                <p></p>
            </div>
        </div>
        <div class="addItem">
            <form>
                <div class="input-field">
                    <h2 class="title">Add an admin user</h2>
                    <label class="label-text" for="email">Email Address</label>
                    <input class="input-auth" placeholder="email" type="email" id="admin-input" autocomplete="email" v-model="email">
                </div>
                <button v-on:click="addAdmin" class="btn-auth">Add admin</button>
            </form>
            <p class="succesText">{{succes}}</p>
            <p class="succesText er">{{fail}}</p>
        </div>
    </div>
    </div>
</main>
</template>

<script>
// @ is an alias to /src
import * as firebase from 'firebase';
import 'firebase/firestore';
import {
    mapGetters,
    mapActions
} from "vuex";

export default {
    name: 'home',
    data() {
        return {
            email: '',
            succes: '',
            fail: '',
            allUserCount: 0,
            adminUsers: 0,
            allUsers: []
        }
    },
    props: {
        navigation: {
            type: Object
        }
    },
    components: {

    },
    created() {
        this.countAllUsers()
    },
    methods: {
        addAdmin: function (e) {
            const addAdminRole = firebase.functions().httpsCallable('addAdminRole')
            addAdminRole({
                email: this.email
            }).then(result => {
                console.log(result)
                if (result.data.message) {
                    this.succes = result.data.message
                    this.fail = ''
                } else {
                    this.succes = ''
                    this.fail = result.data.errorInfo.message
                }

            }).catch(err => {

            })
            e.preventDefault()
        },
        countAllUsers: function (e) {
            const countAllUsers = firebase.functions().httpsCallable('countAllUsers')
            const listAllUsers = firebase.functions().httpsCallable('listAllUsers')
            countAllUsers().then(result => {
                this.allUserCount = result.data.message
                listAllUsers().then(result => {
                    this.allUsers = result.data.message
                    var i
                    for (i = 0; i < this.allUsers.length; i++) {
                        console.log(this.allUsers)
                        if (this.allUsers[i].customClaims) {
                            this.adminUsers += 1
                            console.log(this.adminUsers)
                        }
                    }
                }).catch(err => {

                })

            }).catch(err => {

            })
        }
    },
    computed: {
        ...mapGetters("user", ["authError", "user"]),
        ...mapGetters("shared", ["error"])
    },
}
</script>

<style lang="scss">
@import './src/style/main';

@media (min-width: 71.25em) {
    .home-grid {
        grid-template-columns: 1fr 1fr;
    }
}

@media (min-width: 30em) {
    .home-grid {
        grid-template-columns: 1fr 1fr;
    }
}

#admin-input {
    background: #f6f6fc;
}

.succesText {
    padding-top: 20px;
    font-size: 18px;
}

.headtitle {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 60px;
}

.er {
    color: #ED5E68;
}

.home-grid {
    margin-bottom: 0;

    .addItem {
        margin-bottom: 30px;
        height: 240px;

        p,
        .link-users {
            text-align: center;
        }

        .title {
            text-align: center;
            margin-bottom: 0;
        }

        a {
            margin-top: 12px !important;
            color: #037362;
            width: 100%;
            position: absolute;
            left: 50%;
            transform: translateX(-50%)
        }

    }
}

.counter {
    font-size: 120px;
    text-align: center;
}

.addItem {
    background: #ffffff;
    padding: 35px;
    min-height: 175px;
    border-radius: 10px;
    margin-bottom: 100px;
    position: relative;
}
</style>
