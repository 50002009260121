<template>
    <router-link to="/add-microgreen" class="graph col-sm-4 add-graph">
          <div class="stock add-stock">
              <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 42 42" style="enable-background:new 0 0 42 42;" xml:space="preserve" color="#808080" data-old_color="#808080" >
              <polygon points="42,19 23,19 23,0 19,0 19,19 0,19 0,23 19,23 19,42 23,42 23,23 42,23 " style="fill:#E0E0E0"/>
              </svg>
            <div class="stock-info Add-info">
              <div class="stock-fullname Add-fullname">Add a microgreen</div>
            </div>
          </div>
        </router-link>
</template>

<script>
// @ is an alias to /src


export default {
  name: 'microgreens',
  components: {

  }
}
</script>

<style lang="scss">
  @import './src/style/main';
#Capa_1{
    width: 100px;
    height: 100px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%);
}
.Add-info{
  width: 100%;
  margin: 0;
  padding: 0;
  position: absolute;
  bottom: -10px;
  .Add-fullname{
    left: 50%;
    transform:translateX(-50%);
    position: absolute;
    bottom: -51px;
  }
}
.add-stock{
  margin: unset!important;
}

.add-graph{
  box-shadow: unset!important;
  transition-duration: 600ms;
}
.add-graph:hover{
  box-shadow: 0px 0px 22px -8px #808080!important;
}
</style>

