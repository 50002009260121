<template>
    <div class="authentication">
      <div class="auth">
          <img src="../assets/logo-black.png" alt="logo" class="auth-logo"/>
            <h3 class="title">Login</h3>
            <form >
              <div class="input-field">
                <label class="label-text"  for="email">Email Address</label>
                <input class="input-auth" placeholder="email" type="email"  id="email" autocomplete="email" v-model="credentials.email">
              </div>
              <div class="input-field">
                <label class="label-text" for="password">Password</label>
                <input class="input-auth" placeholder="password" type="password" autocomplete="password" id="password" v-model="credentials.password">
              </div>
              <button v-on:click="doLogIn" class="btn-auth">Login</button>
            </form>
      </div>
    </div>

</template>

<script>
    import firebase from 'firebase';
    import store from '.././store'
    import { db } from '../main';
    import { mapGetters, mapActions } from "vuex";

    export default {
         
        props: {
            navigation: {
                type: Object
            }
        },
        name: 'login',
        data: function() {
            return { 
                userRef:[],
                credentials: {
                    email: '',
                    password: ''
                },
                
            };
        },
        computed: {
            ...mapGetters("user", ["user"]),
         },
        methods: {
            ...mapActions("user", ["userLogin"]),

            doLogIn: function(e) {
                console.log('test1')
                this.$store.dispatch('userLogin', {
                    email: this.credentials.email,
                    password: this.credentials.password
                });

                e.preventDefault();
            },
        }
    };
</script>
