
import Vue from 'vue';
import Vuex from 'vuex';
import firebase from 'firebase';
import router from './router';

Vue.use(Vuex);

export default new Vuex.Store({
    namespaced: true,
    state: {
        user: []
    },
    mutations: {
        setUser(state, payload) {
            state.user = payload;
        },
    },
    actions: {
        userLogin({ commit }, { email, password }) {
            console.log('test2 init', email, password)
            firebase
            .auth()
            .signInWithEmailAndPassword(email, password)
            .then(
                user => {
                    if(user){
                        user.user.getIdTokenResult().then(idTokenResult => {
                            user.admin = idTokenResult.claims.admin
                            if(user.admin){
                                console.log('admin: ', user.admin)
                                commit('setUser', user);
                                router.push({name: 'home'});
                            }else{
                                firebase.auth().signOut().then(() => {
                                    router.replace('login')
                                })
                                console.log('Not the right user permission.')
                            }
                        })
                    }
                    
                },
                err => {
                    alert(err.message);
                }
            ).catch(() => {
                    commit('setUser', null);
                    router.push({name: 'home'});
                });
        },
        userSignOut({ commit }) {
            firebase
                .auth()
                .signOut()
                .then(() => {
                    commit('setUser', null);
                    commit('setIsAuthenticated', false);
                    router.push('/');
                })
                .catch(() => {
                    commit('setUser', null);
                    commit('setIsAuthenticated', false);
                    router.push('/');
                });
        },
    },
    getters: {
        user(state) {
            return state.user;
        },
        loading(state) {
            return state.loading
        },
        error(state) {
        return state.error
        }
    }
});