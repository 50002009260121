<template>
<main>
    <div class="c-top">
        <ul>
            <li>
                <router-link to="/parcel-suite" class="list">
                    <p>Parcels</p>
                    <div class="c-bullet"></div>
                </router-link>
            </li>

            <li>
                <router-link to="/integrations" class="list">
                    <p>Integrations</p>
                    <div class="c-bullet"></div>
                </router-link>
            </li>
        </ul>
    </div>
    <div class="home">
        <div class="home-wrapper">
            <h1 class="headtitle">Integrations</h1>
            <ul>
                <li>
                    <router-link to="#"  class="link ">
                        <div class="graph">
                            <p>,ddkkd</p>
                        </div>
                        <div class="stock-name">
                            <p style="text-decoration: none;">Bol.com</p>
                        </div>
                    </router-link>
                </li>
            </ul>
        </div>
    </div>
</main>
</template>

<script>
// @ is an alias to /src
import axios from 'axios';
import * as firebase from 'firebase';
import 'firebase/firestore';
import {
    mapGetters,
    mapActions
} from "vuex";
const WooCommerceAPI = require('woocommerce-api');

const WooCommerce = new WooCommerceAPI({
    url: 'https://mother.life',
    consumerKey: 'ck_d17058bf6863da2dc750fb530ff4ae38757c1528',
    consumerSecret: 'cs_9cdf64d6a5f763ec5b48f569ec80aad598284201',
    wpAPI: true,
    version: 'wc/v1'
});
export default {
    name: 'home',
    data() {
        return {
            contacts: []
        }
    },
    props: {
        navigation: {
            type: Object
        }
    },
    components: {

    },
    created() {
        const getZohoContacts = firebase.functions().httpsCallable('getZohoContacts')
        getZohoContacts().then(result => {
            this.contacts = result.data.access_token.contacts
        })
    },
    methods: {
        // storeToken() {
        //     firebase.firestore().collection("store-access-tokens").doc(item.DateTimeOrderPlaced).set(this.storeOrders[index])
        //         .then(() => {
        //             console.log("Document successfully written!");
        //         })
        //         .catch(function (error) {
        //             console.error("Error writing document: ", error);
        //         });
        // }
    },
    computed: {
        ...mapGetters("user", ["authError", "user"]),
        ...mapGetters("shared", ["error"])
    },
}
</script>

<style lang="scss">
@import './src/style/main';

@media (min-width: 71.25em) {
    .home-grid {
        grid-template-columns: 1fr 1fr;
    }
}

@media (min-width: 30em) {
    .home-grid {
        grid-template-columns: 1fr 1fr;
    }
}

#admin-input {
    background: #f6f6fc;
}

.succesText {
    padding-top: 20px;
    font-size: 18px;
}

.headtitle {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 60px;
}

.er {
    color: #ED5E68;
}

.home-grid {
    margin-bottom: 0;

    .addItem {
        margin-bottom: 30px;
        height: 240px;

        p,
        .link-users {
            text-align: center;
        }

        .title {
            text-align: center;
            margin-bottom: 0;
        }

        a {
            margin-top: 12px !important;
            color: #037362;
            width: 100%;
            position: absolute;
            left: 50%;
            transform: translateX(-50%)
        }

    }
}

.counter {
    font-size: 120px;
    text-align: center;
}

.addItem {
    background: #ffffff;
    padding: 35px;
    min-height: 175px;
    border-radius: 10px;
    margin-bottom: 100px;
    position: relative;
}
</style>
